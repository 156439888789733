import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Translated from "./Translated";
import { useTranslation } from "./TranslationProvider";

const DropdownInput = (props) => {
  const { translate } = useTranslation();

  const createMenuItems = () => {
    const menuItems = [];

    props.items.forEach((item) => {
      let value = item[0];
      let translationKey = item[1];
      menuItems.push(
        <Dropdown.Item
          id={value}
          key={value}
          onClick={() => {
            props.listener(value);
          }}
        >
          <Translated translationKey={translationKey} />
        </Dropdown.Item>
      );
    });
    return menuItems;
  };

  const menuItems = createMenuItems();
  const label = translate(props.title);

  return (
    <ButtonToolbar className="settings-category-limit">
      <DropdownButton
        id="select-order-batch-dd"
        bsstyle={props.itemStyle ? props.itemStyle : "warning"}
        title={label}
      >
        {menuItems}
      </DropdownButton>
    </ButtonToolbar>
  );
};

export default DropdownInput;
