import { useState } from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import Translated from "../../feature/Translated";
import DateUtil from "../../../utils/date";
import Spinner from "../Spinner";
import { useExportInvoice } from "../../../api-new";
import { useTranslation } from "../TranslationProvider";

const InvoiceListItem = (props) => {
  const { translate } = useTranslation();
  const [exportResult, setExportResult] = useState(null);
  const exportInvoiceMutation = useExportInvoice();

  const exportInvoice = () => {
    exportInvoiceMutation.mutate(
      { invoiceIds: [props.invoiceData.id] },
      {
        onSuccess: (data) => {
          const updatedInvoice = data.invoices.find(
            (invoice) => invoice.id === props.invoiceData.id
          );
          setExportResult(updatedInvoice);
          props.refetchInvoice();
        },
      }
    );
  };

  const renderExport = () => {
    if (exportInvoiceMutation.isPending) {
      return <Spinner />;
    }

    if (exportInvoiceMutation.isError) {
      return <Alert>{translate(exportInvoiceMutation.error.message)}</Alert>;
    }

    const netvisorId =
      props.invoiceData.netvisorId || (exportResult && exportResult.netvisorId);
    if (netvisorId) {
      return (
        <div>
          <Translated translationKey="netvisor_id" />: {netvisorId}
          <br />
          <Translated translationKey="netvisor_status" />:{" "}
          {props.invoiceData.invoiceStatus}
        </div>
      );
    } else {
      return (
        <Button className="top-margin" onClick={exportInvoice}>
          <Translated translationKey="export_invoice" />
        </Button>
      );
    }
  };

  const invoice = props.invoiceData;
  return (
    <div className="blue-inner-box tight">
      <div>
        {invoice.id} <span className="strong">{invoice.schoolId}</span>
        <div>
          <Translated translationKey="date" />:{" "}
          {DateUtil.date(invoice.invoiceDate)}
          <br />
          <Translated translationKey="due_date" />:{" "}
          {DateUtil.date(invoice.dueDate)}
          <br />
          <Translated translationKey="payment_received_date" />:{" "}
          {invoice.paymentReceivedDate}
        </div>
      </div>
      <div>
        <Translated translationKey="total" />: {invoice.total}€{" "}
        <Translated translationKey="total_inc_vat" />:{" "}
        <span className="strong">{invoice.totalIncVat}€</span>
      </div>
      {renderExport()}
    </div>
  );
};

export default InvoiceListItem;
