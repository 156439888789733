import { useParams } from 'react-router-dom';
import PractiseLimits from '../../feature/PractiseLimits';

const AdminPractiseLimitsPage = () => {
  const params = useParams();

  return (
    <div
      id="school-practise-limits-page"
      className="page-container max-width-container"
    >
      <PractiseLimits schoolId={params.schoolId} />
    </div>
  );
};

export default AdminPractiseLimitsPage
