import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Button } from "react-bootstrap";
import { RiCloseFill } from "react-icons/ri";

import SingleItemAccordion from "../SingleItemAccordion";
import Spinner from "../Spinner";
import { useTranslation } from "../TranslationProvider";
import Translated from "../Translated";
import DateFormat from "../../../utils/date";
import AddMaterialModal from "./AddMaterialModal";
import AddCategoryModal from "./AddCategoryModal";
import { useRemoveLicense, useUserLicenses } from "../../../api-new";

const LicenseTypes = {
  category: "category",
  material: "material",
};

const AdminListUserLicenses = function ({ userId }) {
  const { translate } = useTranslation();
  const licensesQuery = useUserLicenses(userId);
  const categories = licensesQuery.data?.categoryLicenses;
  const materials = licensesQuery.data?.materialLicenses;
  const selections = licensesQuery.data?.selections;

  return (
    <div>
      <SingleItemAccordion
        title={
          <div>
            {translate("category_licenses")}&nbsp;
            {licensesQuery.isFetching && <Spinner />}
            {licensesQuery.isError && (
              <div>
                <Alert>{licensesQuery.error.message}</Alert>
              </div>
            )}
          </div>
        }
        content={
          licensesQuery.isSuccess && (
            <CategoriesAccordionContent
              selections={selections.category}
              userId={userId}
              categories={categories}
              refetchLicenses={licensesQuery.refetch}
            />
          )
        }
      />
      <SingleItemAccordion
        title={
          <div>
            {translate("material_licenses")}&nbsp;
            {licensesQuery.isFetching && <Spinner />}
            {licensesQuery.isError && (
              <div>
                <Alert>{licensesQuery.error.message}</Alert>
              </div>
            )}
          </div>
        }
        content={
          licensesQuery.isSuccess && (
            <MaterialsAccordionContent
              selections={selections.material}
              userId={userId}
              materials={materials}
              refetchLicenses={licensesQuery.refetch}
            />
          )
        }
      />
    </div>
  );
};

const CategoriesAccordionContent = ({
  userId,
  categories,
  selections,
  refetchLicenses,
}) => {
  const { translate } = useTranslation();
  const [deletingId, setDeletingId] = useState(null);
  const [isAddLicenseModal, setIsAddLicenseModal] = useState(false);
  const removeLicenseMutation = useRemoveLicense();

  const removeLicense = async (licenseId) => {
    setDeletingId(licenseId);
    removeLicenseMutation.mutate(
      {
        userId,
        data: {
          licenseId,
          licenseType: LicenseTypes.category,
        },
      },
      {
        onSuccess: () => {
          refetchLicenses();
          setDeletingId(null);
        },
      }
    );
  };

  return (
    <div className="admin-user-categories padded-small">
      {selections && (
        <AddCategoryModal
          categoryItems={selections.categorySelection}
          userId={userId}
          isAddLicenseModal={isAddLicenseModal}
          setIsAddLicenseModal={setIsAddLicenseModal}
          refetchLicenses={refetchLicenses}
        />
      )}
      <Button onClick={() => setIsAddLicenseModal(true)}>
        <Translated translationKey="add" />
      </Button>
      <div className="custom-table full-width">
        <div className="custom-table-header">
          <div className="ct-column-name">
            <Translated translationKey="category" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="valid_until" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="group_id" />
          </div>
          <div className="ct-column-name"></div>
        </div>
        <div className="ct-table-body">
          {categories?.map((category) => {
            const { id, validUntil, groupId } = category;
            const daysLeft = validUntil
              ? DateFormat.getRemainingDays(validUntil)
              : null;

            return (
              <div key={id} className="ct-table-row">
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="category" />:
                  </strong>
                  &nbsp;
                  {translate(category.category)}
                </div>
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="valid_until" />:
                  </strong>
                  &nbsp;
                  {validUntil}{" "}
                  {daysLeft && `(${daysLeft} ${translate("days")})`}
                </div>
                <div className="ct-table-body-cell">
                  {groupId && (
                    <>
                      <strong className="ct-mobile-only">
                        <Translated translationKey="group_id" />:
                      </strong>
                      &nbsp;
                      {groupId}
                    </>
                  )}
                </div>
                <div className="ct-table-body-cell">
                  {id === deletingId && removeLicenseMutation.isPending && (
                    <Spinner />
                  )}
                  {!groupId && (
                    <>
                      <RiCloseFill
                        className="ct-desktop-only touchable"
                        onClick={() => removeLicense(id)}
                      />
                      <Button
                        className="ct-mobile-only touchable"
                        onClick={() => removeLicense(id)}
                      >
                        {translate("delete")}
                      </Button>
                    </>
                  )}
                  {id === deletingId && removeLicenseMutation.isError && (
                    <Alert>
                      {translate(removeLicenseMutation.error.message)}
                    </Alert>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const MaterialsAccordionContent = ({
  userId,
  materials,
  refetchLicenses,
  selections,
}) => {
  const { translate } = useTranslation();
  const [deletingId, setDeletingId] = useState(null);
  const [isAddLicenseModal, setIsAddLicenseModal] = useState(false);
  const removeLicenseMutation = useRemoveLicense();

  const removeLicense = async (licenseId) => {
    setDeletingId(licenseId);
    removeLicenseMutation.mutate(
      {
        userId,
        data: {
          licenseId,
          licenseType: LicenseTypes.material,
        },
      },
      {
        onSuccess: () => {
          refetchLicenses();
          setDeletingId(null);
        },
      }
    );
  };

  return (
    <div className="admin-user-categories padded-small">
      {selections && (
        <AddMaterialModal
          materialTypes={selections.materialTypeSelection}
          materialIds={selections.materialIdSelection}
          userId={userId}
          isAddLicenseModal={isAddLicenseModal}
          setIsAddLicenseModal={setIsAddLicenseModal}
          refetchLicenses={refetchLicenses}
        />
      )}
      <Button>
        <Translated
          onClick={() => setIsAddLicenseModal(true)}
          translationKey="add"
        />
      </Button>
      <div className="custom-table full-width">
        <div className="custom-table-header">
          <div className="ct-column-name">
            <Translated translationKey="type" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="id" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="valid_until" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="group_id" />
          </div>
          <div className="ct-column-name"></div>
        </div>
        <div className="ct-table-body">
          {materials?.map((material) => {
            const { id, materialType, materialId, validUntil, groupId } =
              material;
            const daysLeft = validUntil
              ? DateFormat.getRemainingDays(validUntil)
              : null;

            return (
              <div key={id} className="ct-table-row">
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="type" />:
                  </strong>
                  &nbsp;
                  {translate(materialType)}
                </div>
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="id" />:
                  </strong>
                  &nbsp;
                  {translate(materialId)}
                </div>
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="valid_until" />:
                  </strong>
                  &nbsp;
                  {validUntil}{" "}
                  {daysLeft && `(${daysLeft} ${translate("days")})`}
                </div>
                <div className="ct-table-body-cell">
                  {groupId && (
                    <>
                      <strong className="ct-mobile-only">
                        <Translated translationKey="group_id" />:
                      </strong>
                      &nbsp;
                      {groupId}
                    </>
                  )}
                </div>
                <div className="ct-table-body-cell">
                  {id === deletingId && removeLicenseMutation.isPending && (
                    <Spinner />
                  )}
                  {!groupId && (
                    <>
                      <RiCloseFill
                        className="ct-desktop-only touchable"
                        onClick={() => removeLicense(id)}
                      />
                      <Button
                        className="ct-mobile-only touchable"
                        onClick={() => removeLicense(id)}
                      >
                        {translate("delete")}
                      </Button>
                    </>
                  )}
                  {id === deletingId && removeLicenseMutation.isError && (
                    <Alert>
                      {translate(removeLicenseMutation.error.message)}
                    </Alert>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminListUserLicenses;
