import { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {
  RiComputerLine,
  RiWindow2Fill,
  RiErrorWarningLine,
} from "react-icons/ri";
import { BsCalendarDate } from "react-icons/bs";

import { useTranslation } from "../TranslationProvider";
import SingleItemAccordion from "../SingleItemAccordion";
import Spinner from "../Spinner";
import { stringToHexColor } from "../../../utils/common";
import { useLoginLogs } from "../../../api-new";

const USER_AGENT_MAX_LENGTH = 11;
const MAX_LOGIN_LOGS_LIMIT = 50;

const LoginLogs = ({ userId }) => {
  const { translate } = useTranslation();
  const [logDetails, setLogDetails] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [isShowingAllLogs, setIsShowingAllLogs] = useState(false);
  const logsQuery = useLoginLogs(userId);

  const getUnique = (logs, propertyName) => {
    const allResults = logs?.map((log) => log[propertyName]);
    const uniqueResults = new Set(allResults);

    return uniqueResults.size;
  };

  const renderModal = () => {
    return (
      <Modal
        className="modal-wrap"
        onEscapeKeyDown={() => setIsModal(false)}
        show={isModal}
      >
        <Modal.Body>
          {Object.entries(logDetails).map((entry) => {
            const [key, value] = entry;

            if (typeof value === "object") {
              return;
            }

            return (
              <p key={key}>
                <strong>{key}: </strong> {value}
              </p>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsModal(false)}>
            {translate("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const getAccordionContent = () => {
    const logs = logsQuery.data.loginLogs;

    const uniqueMachineIds = getUnique(logs, "machineId");
    const uniqueUserAgents = getUnique(logs, "userAgent");

    return (
      <div>
        {renderModal()}
        <div className="admin-login-logs padded-small">
          <div className="logs-overview">
            <div>
              <h5>{translate("total_logins") + ": " + logs?.length}</h5>
            </div>
            <div>
              <h5>{translate("unique_machines") + ": " + uniqueMachineIds}</h5>
            </div>
            <div>
              <h5>
                {translate("unique_user_agents") + ": " + uniqueUserAgents}
              </h5>
            </div>
          </div>
          <hr />
          <div className="logs-container">
            {logs
              ?.slice(0, isShowingAllLogs ? logs.length : MAX_LOGIN_LOGS_LIMIT)
              .map((log) => (
                <div
                  key={log.id}
                  className="login-log-item touchable"
                  onClick={() => {
                    setLogDetails(log);
                    setIsModal(true);
                  }}
                >
                  <div>
                    <BsCalendarDate />
                    &nbsp;
                    {log.localDate}
                  </div>
                  <div>
                    <RiComputerLine />
                    &nbsp;
                    {log.machineId}
                  </div>
                  <div>
                    <RiWindow2Fill />
                    &nbsp;
                    {log.userAgent.slice(0, USER_AGENT_MAX_LENGTH)}&nbsp;
                    <span style={{ color: stringToHexColor(log.userAgent) }}>
                      &#11044;
                    </span>
                  </div>
                  {log.error && (
                    <div
                      className="alert alert-danger m-0 p-0 px-1"
                      role="alert"
                    >
                      <RiErrorWarningLine />
                      &nbsp;
                      {log.error}
                    </div>
                  )}
                </div>
              ))}
          </div>
          {!isShowingAllLogs && logs?.length > MAX_LOGIN_LOGS_LIMIT && (
            <Button onClick={() => setIsShowingAllLogs(true)}>
              {translate("show_all")}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const renderFetching = () => {
    return (
      <SingleItemAccordion
        title={
          <div>
            <Spinner /> {translate("login_logs")}
          </div>
        }
        content=""
      />
    );
  };

  const renderContent = () => {
    const logs = logsQuery.data.loginLogs;

    return (
      <SingleItemAccordion
        title={translate("login_logs")}
        content={
          logs?.length === 0 ? (
            <p>{translate("not_available")}</p>
          ) : (
            getAccordionContent()
          )
        }
      />
    );
  };

  return (
    <div>
      {logsQuery.isPending && renderFetching()}
      {logsQuery.isError && <Alert>{logsQuery.error.message}</Alert>}
      {logsQuery.isSuccess && renderContent()}
    </div>
  );
};

export default LoginLogs;
