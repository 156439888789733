import { Alert } from "react-bootstrap";
import classNames from "classnames";

import Spinner from "../Spinner";
import { useTranslation } from "../TranslationProvider";
import { useUserSubscriptionPeriods } from "../../../api-new";

const SubscriptionPeriods = ({ userId }) => {
  const { translate } = useTranslation();
  const subscriptionPeriodsQuery = useUserSubscriptionPeriods(userId);

  const renderSpinner = () => {
    return (
      <div className="blue-box">
        <Spinner />
      </div>
    );
  };

  const renderError = () => {
    return (
      <div>
        <Alert>{subscriptionPeriodsQuery.error.message}</Alert>
      </div>
    );
  };

  const renderEmptyPeriods = () => {
    return (
      <div className="blue-box">
        {translate("subscription_period")} {translate("not_available")}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="blue-box">
        {translate("subscription_period")}:{" "}
        {subscriptionPeriods?.map((period) => {
          return (
            <div
              key={period.id}
              className={classNames("list-item-opaque", {
                "sub-period-expired": period.expired,
              })}
            >
              <p className="no-margin">
                {period.localStartDate}, {translate("valid_until")}{" "}
                {period.localEndDate}, {period.userSchoolId}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  if (subscriptionPeriodsQuery.isPending) {
    return renderSpinner();
  }

  if (subscriptionPeriodsQuery.isError) {
    return renderError();
  }

  const subscriptionPeriods = subscriptionPeriodsQuery.data.subscriptionPeriods;

  return (
    <div>
      {subscriptionPeriods?.length === 0
        ? renderEmptyPeriods()
        : renderContent()}
    </div>
  );
};

export default SubscriptionPeriods;
