import Translated from "../../feature/Translated";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InvoiceablePeriodList from "../../feature/admin/InvoiceablePeriodList";
import classNames from "classnames";
import FormGroup from "react-bootstrap/FormGroup";
import ControlLabel from "react-bootstrap/FormLabel";
import { useState } from "react";
import { useTranslation } from "../../feature/TranslationProvider";

const DEFAULT_THRESHOLD_EUR = 20;

const InvoicingPage = () => {
  const { translate } = useTranslation();
  const [filters, setFilters] = useState({
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toISOString(),
    threshold: DEFAULT_THRESHOLD_EUR,
  });
  const [queryFilters, setQueryFilters] = useState(null);

  const handleFilterChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const applyFilters = () => {
    setQueryFilters(filters);
  };

  const makeFormGroup = (label, fieldName, classes) => {
    classes = classNames("settings-field", classes);
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          className={classes}
          name={fieldName}
          placeholder={label}
          value={filters[fieldName] ? filters[fieldName] : ""}
          onChange={handleFilterChange}
        />
      </FormGroup>
    );
  };

  // Transform threshold filter from euros to cents here.
  const filtersAdjusted = queryFilters && {
    ...queryFilters,
    threshold: queryFilters.threshold * 100,
  };

  return (
    <div className="page-container max-width-container">
      <h2>
        <Translated translationKey="invoicing" />
      </h2>

      <div className="blue-box">
        {makeFormGroup(translate("end_date"), "endDate")}
        {makeFormGroup(translate("invoicing_threshold"), "threshold")}
        <Button onClick={applyFilters}>
          <Translated translationKey="apply" />
        </Button>
      </div>

      <InvoiceablePeriodList filters={filtersAdjusted} />
    </div>
  );
};

export default InvoicingPage;
