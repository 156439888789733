import { useState } from "react";
import { IoCheckmarkSharp, IoClose } from "react-icons/io5";
import Button from "react-bootstrap/Button";

import ZoomableImage from "../ZoomableImage";
import TextInput from "../TextInput";
import Translated from "../Translated";
import Reports from "./Reports";
import BookModalLink from "../BookModalLink";
import { useAddBookReference } from "../../../api-new";

const ImageQuestion = ({ question, category, lang, refetchImages }) => {
  const [unsavedBookReference, setUnsavedBookReference] = useState("");
  const bookReferenceMutation = useAddBookReference();

  const renderQuestionAndAnswer = () => {
    const correctAnswer =
      question.isAssertionTruthy === true ? (
        <IoCheckmarkSharp className="primary" />
      ) : (
        <IoClose className="red" />
      );

    return (
      <div className="test-result-image-question">
        {question.id}. &nbsp;
        {question.assertion} &nbsp;
        {correctAnswer} &nbsp;
      </div>
    );
  };

  const renderExplanations = () => {
    return question.descriptions.map((exp) => {
      // Use images if svg is missing.
      const src = exp.imageUrl;

      let img = src ? (
        <div>
          <img className="test-result-image-explanation-image" src={src} />
        </div>
      ) : null;
      let text = <div>{exp.text}</div>;
      return (
        <div key={exp.id} className="test-result-image-explanation-wrapper">
          {img}
          {text}
        </div>
      );
    });
  };

  const bookReferenceListener = (refId) => {
    setUnsavedBookReference(refId);
  };

  const saveReference = () => {
    bookReferenceMutation.mutate(
      {
        questionType: "image",
        questionId: question.id,
        category,
        referenceId: unsavedBookReference,
      },
      {
        onSuccess: () => {
          refetchImages();
        },
      }
    );
  };

  const renderReports = () => {
    const reports = question.reports;
    return reports ? <Reports reports={reports} /> : null;
  };

  const renderBookContent = () => {
    if (question.bookReferenceIds.length === 0) {
      return (
        <div>
          <TextInput
            listener={(value) => bookReferenceListener(value)}
            value={unsavedBookReference}
            placeholder="book reference id"
          />
          <Button onClick={saveReference}>
            <Translated translationKey="save" />
          </Button>
          {bookReferenceMutation.isError && (
            <span>
              <Translated
                translationKey={bookReferenceMutation.error.message}
              />
            </span>
          )}
        </div>
      );
    }

    return question.bookReferenceIds.map((refId) => (
      <BookModalLink lang={lang} contentId={refId} showId key={refId} />
    ));
  };

  return (
    <div className="test-result-image-wrapper" key={question.id}>
      <ZoomableImage
        src={question.imageUrl}
        className="test-result-image-image"
        modalClassName="test-result-image-modal"
      />
      <div className="test-result-image-explanations-wrapper">
        {renderQuestionAndAnswer()}
        {renderExplanations()}
        {renderBookContent()}
        {renderReports()}
        <span className="smaller semi-transparent">{question.imageUrl}</span>
      </div>
    </div>
  );
};

export default ImageQuestion;
