import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";

import DropdownInput from "../DropdownInput";
import Spinner from "../Spinner";
import { useTranslation } from "../TranslationProvider";
import { useAddCategoryLicense } from "../../../api-new";

const DEFAULT_VALIDITY_PERIOD_DAYS = 14;

const AddCategoryModal = ({
  userId,
  refetchLicenses,
  isAddLicenseModal,
  setIsAddLicenseModal,
  categoryItems,
}) => {
  const { translate } = useTranslation();
  const [category, setCategory] = useState(categoryItems[0]);
  const [validForDays, setValidForDays] = useState(
    DEFAULT_VALIDITY_PERIOD_DAYS
  );
  const categoryLicenseMutation = useAddCategoryLicense();

  const addLicense = async () => {
    categoryLicenseMutation.mutate(
      {
        userId,
        data: { category, validForDays },
      },
      {
        onSuccess: () => {
          refetchLicenses();
          setIsAddLicenseModal(false);
        },
      }
    );
  };

  return (
    <Modal show={isAddLicenseModal} onHide={() => setIsAddLicenseModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("license_information")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{translate("category") + ":"}</Form.Label>
        <DropdownInput
          listener={(value) => setCategory(value)}
          value={category}
          title={category}
          items={categoryItems.map((category) => [category, category])}
        />

        <Form.Group className={"mb-3"}>
          <Form.Label>{translate("duration_days")}</Form.Label>
          <Form.Control
            type="number"
            name="input"
            placeholder={translate("duration_days")}
            value={validForDays}
            onChange={(e) =>
              setValidForDays(parseInt(e.target.value, 10) || "")
            }
          />
        </Form.Group>
        {categoryLicenseMutation.isError && (
          <Alert>{t(categoryLicenseMutation.error.message)}</Alert>
        )}
        {categoryLicenseMutation.isPending && <Spinner />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={addLicense}>{translate("add")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCategoryModal;
