import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { IoSearch } from "react-icons/io5";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import Translated from "./../Translated";
import Spinner from "./../../feature/Spinner";
import { useSearchSchools } from "../../../api-new";
import { useTranslation } from "../TranslationProvider";

const SchoolSearch = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [term, setTerm] = useState("");
  const searchSchoolsMutation = useSearchSchools();

  const searchSchools = () => {
    searchSchoolsMutation.mutate({ searchTerm: term });
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    searchSchools();
  };

  const editSchoolSettings = (schoolId) => {
    navigate("/school-settings/" + schoolId);
  };

  const editSchoolLimits = (schoolId) => {
    navigate("/school-limits/" + schoolId);
  };

  const orderOneTimeKeys = (schoolId) => {
    navigate("/school-orders/" + schoolId);
  };

  const listInvoices = (schoolId) => {
    navigate("/school-invoices/" + schoolId);
  };

  const renderSchoolItem = (school) => {
    return (
      <div key={school.id}>
        <div>
          {school.name}
          <br />
        </div>
        <div className="lighter semi-transparent">
          [{school.series}] {school.phoneNumber} {school.email}{" "}
          {school.streetAddress} {school.postalLocation}
          <br />
        </div>
        {school.id}
        <Button bsstyle="link" onClick={() => editSchoolSettings(school.id)}>
          <Translated translationKey="edit" />
        </Button>
        <Button bsstyle="link" onClick={() => editSchoolLimits(school.id)}>
          <Translated translationKey="limits" />
        </Button>
        <Button bsstyle="link" onClick={() => orderOneTimeKeys(school.id)}>
          <Translated translationKey="orders" />
        </Button>
        <Button bsstyle="link" onClick={() => listInvoices(school.id)}>
          <Translated translationKey="invoices" />
        </Button>
        <hr />
      </div>
    );
  };

  const renderSchools = () => {
    if (searchSchoolsMutation.isPending) {
      return <Spinner />;
    }

    if (searchSchoolsMutation.isError) {
      return <Alert>{searchSchoolsMutation.error.message}</Alert>;
    }

    const schools = searchSchoolsMutation.data;

    if (!schools) {
      return null;
    }

    if (schools.length === 0) {
      return (
        <Alert className="top-margin-small">
          <Translated translationKey="no_search_results" />
        </Alert>
      );
    }

    return (
      <div className="medium-top-margin">
        {schools.map((school) => renderSchoolItem(school))}
      </div>
    );
  };

  return (
    <div className="school-list">
      <div id="user-search-container">
        <Form onSubmit={onSubmit} className="fade-in">
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>
                <IoSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="term"
                placeholder={translate("search_schools")}
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
              <InputGroup>
                <Button id="user-search-button" onClick={onSubmit}>
                  {translate("search_do")}
                </Button>
              </InputGroup>
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      {renderSchools()}
    </div>
  );
};

export default SchoolSearch;
