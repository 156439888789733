import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import ImageQuestion from "../../feature/admin/ImageQuestion";
import Spinner from "../../feature/Spinner";
import TextInput from "../../feature/TextInput";
import Translated from "../../feature/Translated";
import DropdownInput from "../../feature/DropdownInput";
import { useTranslation } from "../../feature/TranslationProvider";
import { useListImageQuestions } from "../../../api-new";

const SORTING_VALUES = [
  [null, "none"],
  ["reports", "reports"],
  ["bookRefs", "book_references"],
  ["assertions", "assertions"],
  ["descriptions", "descriptions"],
];

const LANGUAGES = Object.keys(LANGUAGE_SELECTIONS).map((lang) => [
  lang,
  LANGUAGE_SELECTIONS[lang],
]);

const AdminImagesPage = () => {
  const { translate } = useTranslation();
  const [language, setLanguage] = useState("fi");
  const [category, setCategory] = useState("b");
  const [id, setId] = useState("");
  const [assertion, setAssertion] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [queryInputs, setQueryInputs] = useState({ category, language, id });
  const imagesQuery = useListImageQuestions(
    queryInputs.category,
    queryInputs.language,
    queryInputs.id
  );

  const filterListener = (filter, value) => {
    switch (filter) {
      case "language":
        setLanguage(value);
        break;
      case "category":
        setCategory(value);
        break;
      case "assertion":
        setAssertion(value);
        break;
      case "id":
        setId(value);
        break;
    }
  };

  const initFilters = () => {
    setQueryInputs({ language, category, id });
  };

  const sortListener = (value) => {
    setSortBy(value);
  };

  const sortQuestionsOnId = (question1, question2) => {
    const id1 = question1.id;
    const id2 = question2.id;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  };

  const sortQuestionsOnReportsDesc = (question1, question2) => {
    const len1 = question1.reports ? question1.reports.length : 0;
    const len2 = question2.reports ? question2.reports.length : 0;
    if (len1 === len2) {
      return 0;
    }
    return len1 > len2 ? -1 : 1;
  };

  const sortQuestionsOnBookRefs = (question1, question2) => {
    const id1 = question1.bookReferenceIds
      ? question1.bookReferenceIds[0]
      : null;
    const id2 = question2.bookReferenceIds
      ? question2.bookReferenceIds[0]
      : null;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  };

  const sortQuestionsOnDescriptions = (question1, question2) => {
    const id1 = question1.descriptions ? question1.descriptions[0].id : null;
    const id2 = question2.descriptions ? question2.descriptions[0].id : null;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  };

  const sortQuestionsOnAssertions = (question1, question2) => {
    let ass1 = question1.assertion;
    let id1 = question1.assertionId.toString();
    if (id1 !== "") {
      ass1 = parseInt(id1.replace(/[^0-9]/g, ""));
    }

    let ass2 = question2.assertion;
    let id2 = question2.assertionId.toString();
    if (id2 !== "") {
      ass2 = parseInt(id2.replace(/[^0-9]/g, ""));
    }

    return ass1 > ass2 ? 1 : -1;
  };

  const renderQuestions = useMemo(() => {
    if (imagesQuery.isFetching) {
      return <Spinner />;
    }

    if (imagesQuery.isError) {
      return (
        <div>
          <Alert>
            <Translated translationKey={imagesQuery.error.message} />
          </Alert>
        </div>
      );
    }

    const questions = imagesQuery.data?.questions;
    if (!questions) {
      return;
    }

    switch (sortBy) {
      case "reports":
        questions.sort(sortQuestionsOnReportsDesc);
        break;
      case "assertions":
        questions.sort(sortQuestionsOnAssertions);
        break;
      case "bookRefs":
        questions.sort(sortQuestionsOnBookRefs);
        break;
      case "descriptions":
        questions.sort(sortQuestionsOnDescriptions);
        break;
      default:
        questions.sort(sortQuestionsOnId);
    }

    const elems = [];
    const assertionFilter = assertion;
    questions.forEach((imageQuestion) => {
      if (
        assertionFilter === "" ||
        assertionFilter === imageQuestion.assertionId.toString()
      ) {
        elems.push(
          <ImageQuestion
            key={imageQuestion.id}
            question={imageQuestion}
            category={category}
            lang={language}
            refetchImages={imagesQuery.refetch}
          />
        );
      }
    });
    return elems;
  }, [
    imagesQuery.data,
    imagesQuery.isFetching,
    imagesQuery.isError,
    imagesQuery.error,
    sortBy,
  ]);

  return (
    <div className="page-container page-gutter max-width-container">
      <DropdownInput
        listener={(value) => filterListener("language", value)}
        value={language}
        title={LANGUAGES.find((lang) => lang[0] === language)[1]}
        items={LANGUAGES}
      />
      <TextInput
        listener={(value) => filterListener("category", value)}
        value={category}
        placeholder="category"
        autoComplete="off"
      />
      <TextInput
        listener={(value) => filterListener("assertion", value)}
        value={assertion}
        placeholder="assertion"
        autoComplete="off"
      />
      <TextInput
        listener={(value) => filterListener("id", value)}
        value={id}
        placeholder="id"
        autoComplete="off"
      />
      <DropdownInput
        listener={(value) => sortListener(value)}
        value={sortBy}
        title="sorting"
        items={SORTING_VALUES}
      />
      <Button id="list-images-button" onClick={initFilters}>
        {translate("search_do")}
      </Button>
      {renderQuestions}
    </div>
  );
};

export default AdminImagesPage;
