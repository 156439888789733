import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Translated from "../../feature/Translated";
import LoginFormEmail from "../../feature/LoginFormEmail";
import { useUserData } from "../../../context/UserDataContext";

const AdminLoginPage = () => {
  const { token } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (token) {
      // Login happened
      navigate("/", {
        state: location.state,
        replace: true,
      });
    }
  }, [token]);

  let info;
  const redirect = location.state ? location.state.redirect : null;
  if (token && redirect) {
    info = (
      <div className="padded">
        <Translated translationKey="insufficient-rights" />
      </div>
    );
  }

  return (
    <div
      id="teacher-login-page"
      className="page-container page-gutter max-width-container"
    >
      <div className="front-center-wrapper">
        <div className="login-form-container fade-in">
          {info}
          <LoginFormEmail key="email-form" disableRecovery={true} />
          <br />
        </div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
