import Button from "react-bootstrap/Button";
import FormGroup from "react-bootstrap/FormGroup";
import ControlLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import classNames from "classnames";

import Spinner from "../Spinner";
import InvoiceablePeriodListItem from "./InvoiceablePeriodListItem";
import Translated from "../Translated";
import { useCreateInvoices, useInvoiceablePeriods } from "../../../api-new";
import { useTranslation } from "../TranslationProvider";
import { useEffect, useRef, useState } from "react";
import InvoiceListItem from "./InvoiceListItem";

const ALLOWED_FILTERS = ["endDate", "threshold"];

const InvoiceablePeriodList = ({ filters }) => {
  const { translate } = useTranslation();
  const [invoiceDate, setInvoiceDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const prevFiltersRef = useRef(filters);
  const invoicesQuery = useInvoiceablePeriods(filters);
  const invoicesMutation = useCreateInvoices();

  useEffect(() => {
    const haveFiltersChanged = (prevFilters, newFilters) => {
      const a = prevFilters ? prevFilters : {};
      const b = newFilters ? newFilters : {};
      return ALLOWED_FILTERS.reduce((hasChanges, filterName) => {
        return hasChanges || a[filterName] !== b[filterName];
      }, false);
    };

    if (haveFiltersChanged(prevFiltersRef.current, filters)) {
      invoicesQuery.refetch();
      prevFiltersRef.current = filters;
    }
  }, [filters]);

  const postCreate = () => {
    // TODO Create invoices for user selected schools. For now, send all schoolIds we have
    const schoolIds = periods.map((period) => period.schoolId);

    invoicesMutation.mutate(
      {
        schoolIds,
        filters,
        invoiceDate,
        dueDate,
      },
      {
        onSuccess: () => {
          invoicesQuery.refetch();
        },
      }
    );
  };

  const makeFormGroup = (label, fieldName, classes) => {
    classes = classNames("settings-field", classes);
    let value;
    let onChange;

    switch (fieldName) {
      case "invoiceDate":
        value = invoiceDate;
        onChange = (value) => setInvoiceDate(value);
        break;

      case "dueDate":
        value = dueDate;
        onChange = (value) => setDueDate(value);
        break;
    }
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          className={classes}
          name={fieldName}
          placeholder={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </FormGroup>
    );
  };

  if (invoicesQuery.isFetching) {
    return <Spinner />;
  }

  if (!invoicesQuery.data) {
    return null;
  }

  const periods = invoicesQuery.data;

  let createInvoicesButton;
  if (periods && periods.length) {
    createInvoicesButton = (
      <div>
        {makeFormGroup(translate("invoice_date"), "invoiceDate")}
        {makeFormGroup(translate("due_date"), "dueDate")}
        <Button className="top-margin" bsstyle="warning" onClick={postCreate}>
          <Translated translationKey="create_invoices" />
        </Button>
      </div>
    );
  }

  const items = periods.map((period) => {
    return (
      <InvoiceablePeriodListItem periodData={period} key={period.schoolId} />
    );
  });

  let invoices;
  if (invoicesMutation.isSuccess && invoicesMutation.data) {
    invoices = invoicesMutation.data.map((invoice) => {
      return (
        <InvoiceListItem
          invoiceData={invoice}
          key={invoice.id}
          refetchInvoice={invoicesQuery.refetch}
        />
      );
    });
  }

  return (
    <div>
      {createInvoicesButton}
      {invoicesMutation.isError && <div>{invoicesMutation.error.message}</div>}
      {!invoices && <div>{items}</div>}
      {invoicesQuery.isError && <div>{invoicesQuery.error.message}</div>}
      <div>{invoices}</div>
    </div>
  );
};

export default InvoiceablePeriodList;
