import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import TextInput from "../../feature/TextInput";
import Translated from "../../feature/Translated";
import {
  useTokenForUserEmailLogin,
  useTokenForUserIdLogin,
} from "../../../api-new";
import Spinner from "../../feature/Spinner";

const AdminLoginAsUserPage = () => {
  const userIdInputRef = useRef("");
  const userEmailInputRef = useRef("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const tokenUserIdQuery = useTokenForUserIdLogin(userId);
  const tokenUserEmailQuery = useTokenForUserEmailLogin(userEmail);

  const requestLinkForUserId = () => {
    setUserId(userIdInputRef.current);
  };

  const requestLinkForEmail = () => {
    setUserEmail(userEmailInputRef.current);
  };

  const renderLinkData = (queryData) => {
    if (queryData.isFetching) {
      return <Spinner />;
    }

    if (queryData.isError) {
      return (
        <Alert>
          <Translated translationKey={queryData.error.message} />
        </Alert>
      );
    }

    if (!queryData.data) {
      return;
    }

    return (
      <div>
        <a href={queryData.data.url} target="_blank" rel="noopener noreferrer">
          {queryData.data.userName} &gt;&gt;
        </a>
      </div>
    );
  };

  const renderLoginAsUserId = () => {
    return (
      <div className="medium-top-margin">
        <Translated translationKey="user_id" />
        <TextInput listener={(value) => (userIdInputRef.current = value)} />
        <Button onClick={() => requestLinkForUserId()}>
          <Translated translationKey="search_do" />
        </Button>
        {renderLinkData(tokenUserIdQuery)}
      </div>
    );
  };

  const renderLoginAsUserEmail = () => {
    return (
      <div className="large-top-margin">
        <Translated translationKey="email" />
        <TextInput listener={(value) => (userEmailInputRef.current = value)} />
        <Button onClick={() => requestLinkForEmail()}>
          <Translated translationKey="search_do" />
        </Button>
        {renderLinkData(tokenUserEmailQuery)}
      </div>
    );
  };

  return (
    <div
      id="school-settings-page"
      className="page-container max-width-container"
    >
      <h2>
        <Translated translationKey="login_as_user" />
      </h2>
      {renderLoginAsUserId()}
      {renderLoginAsUserEmail()}
    </div>
  );
};

export default AdminLoginAsUserPage;
