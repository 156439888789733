import SchoolAdd from "../../feature/admin/SchoolAdd";
import { useTranslation } from "../../feature/TranslationProvider";

const AdminSchoolSettingsPage = () => {
  const { translate } = useTranslation();

  return (
    <div
      id="school-settings-page"
      className="page-container max-width-container"
    >
      <h1>{translate("menu_add_school")}</h1>
      <SchoolAdd />
    </div>
  );
};

export default AdminSchoolSettingsPage;
