export const clientSideAdmin = {
  "fi" : {
    "prepaid_date": "Käyttö maksettu: {date}",
    "soft_closing_date": "Uusien käyttäjien lisääminen estetty: {date}",
    "license_free_until": "Veloitukseton käyttö voimassa: {date}",
    "closing_date": "Tili sulkeutuu: {date}",
    "invoicing_interval_months": "Laskutusväli",
    "student_amount": "Oppilasmäärä",
    "school_series": "Sarja",
    "parent_school_id": "Isäntäkoulun tunnus",
    "preferred_language": "Ensisijainen kieli",
    "allow_closed_reminders": "Salli sulkemismuistutukset",
    "allow_receipt_send": "Salli kuittausten lähetys",
    "is_closed": "Koulu on suljettu",
    "has_teacher_page": "Opettajan sivut käytössä",
    "student_codes_batch_amount": "Luotavien koodien määrä",
    "limits": "Harjoittelurajat",
    "clear_caches": "Tyhjennä välimuistit",
    "search_schools": "Hae kouluja",
    "menu_add_school": "Lisää koulu",
    "login_as_user": "Kirjaudu käyttäjänä",
    "menu_list_literals": "Listaa sanalliset",
    "menu_list_images": "Listaa kuvatehtävät",
    "menu_invoicing": "Laskutus",
    "edit": "Muokkaa",
    "add_comment": "Lisää kommentti",
    "sorting": "Järjestä",
    "assertion": "Väite",
    "language": "Kieli",
    "category": "Ajokorttiluokka",
    "show": "Näytä",
    "invoicing": "Laskujen luonti",
    "url": "Verkko-osoite",
    "none": "Ei mitään",
    "reports": "Raportit",
    "book_references": "Kirjan viittaukset",
    "assertions": "Väitteet",
    "descriptions": "Kuvaukset",
    "due_date": "Eräpäivä",
    "total": "Yhteensä",
    "total_inc_vat": "Yhteensä (ALV)",
    "payment_received_date": "Maksettu pvm",
    "invoices": "Laskut",
    "start_date": "Alkaen",
    "end_date": "Päättyen",
    "apply": "Käytä",
    "user_discount_percentage": "Alennus käyttäjistä (%)",
    "netvisor_id": "Netvisor ID",
    "netvisor_status": "Laskun tila",
    "export_invoice": "Vie Netvisoriin",
    "invoicing_threshold": "Laskun minimisumma (€)",
    "period_count": "Käyttäjiä (laskutettavia jaksoja)",
    "invoice_total": "Loppusumma",
    "create_invoices": "Luo laskut",
    "invoice_date": "Laskun päiväys",
    "menu_webshop_orders": "Verkkokauppaostokset",
    "order_info_page": "Tilauksen infosivu",
    "add_do": "Lisää",
    "webshop_orders": "Verkkokaupan tilaukset",
    "duration": "Kesto",
    "not_available": "N/A",
    "category_licenses":"Ajokorttiluokkien lisenssit",
    "material_licenses":"Materiaalien lisenssit",
    "subscription_period": "Tilausaika",
    "login_logs": "Kirjautumislokit",
    "status": "Tila",
    "remove_password": "Poista salasana",
    "remove": "Poista",
    "set": "Aseta"

  },
  en: {
    "prepaid_date": "Prepaid date: {date}",
    "soft_closing_date": "Prevent adding new users from: {date}",
    "license_free_until": "License valid until: {date}",
    "closing_date": "Closing date: {date}",
    "invoicing_interval_months": "Billing interval",
    "student_amount": "Student amount",
    "school_series": "Series",
    "parent_school_id": "Parent school id",
    "preferred_language": "Preferred language",
    "allow_closed_reminders": "Allow reminders about closed school",
    "allow_receipt_send": "Allow notifications for accepted students",
    "is_closed": "The school is closed",
    "has_teacher_page": "School has a teacher page",
    "student_codes_batch_amount": "Number of codes to be generated",
    "limits": "Training limits",
    "clear_caches": "Reload caches",
    "search_schools": "Search for schools",
    "menu_add_school": "Add a school",
    "login_as_user": "Log in as a user",
    "menu_list_literals": "List literals",
    "menu_list_images": "List images",
    "menu_invoicing": "Billing",
    "edit": "Edit",
    "add_comment": "Add a comment",
    "sorting": "Sort",
    "assertion": "Assertion",
    "language": "Language",
    "category": "Category",
    "show": "Show",
    "invoicing": "Invoicing",
    "url": "Web address",
    "none": "None",
    "reports": "Reports",
    "book_references": "Book references",
    "assertions": "Assertions",
    "descriptions": "Descriptions",
    "due_date": "Due date",
    "total": "Total",
    "total_inc_vat": "Total (VAT)",
    "payment_received_date": "Paid date",
    "invoices": "Invoices",
    "start_date": "From",
    "end_date": "Until",
    "apply": "Apply",
    "user_discount_percentage": "User discount (%)",
    "netvisor_id": "Netvisor ID",
    "netvisor_status": "Invoice status",
    "export_invoice": "Export to Netvisor",
    "invoicing_threshold": "Minimum invoice amount (€)",
    "period_count": "User amount (period count)",
    "invoice_total": "Invoice amount",
    "create_invoices": "Create invoices",
    "invoice_date": "Invoice date",
    "menu_webshop_orders": "Webshop orders",
    "order_info_page": "Order information",
    "add_do": "Add",
    "webshop_orders": "Webshop orders",
    "duration": "Duration",
    "not_available": "N/A",
    "category_licenses":"Category licenses",
    "material_licenses":"Material licenses",
    "subscription_period": "Subscription period",
    "login_logs": "Login logs",
    "status": "Status",
    "remove_password": "Remove password",
    "disconnect": "Disconnect",
    "set": "Set"
  }
};