import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Pagination,
} from "react-bootstrap";
import { FiChevronRight } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import DateFormat from "../../../utils/date";
import Spinner from "../Spinner";
import { useTranslation } from "../TranslationProvider";
import { useEventLogs } from "../../../api-new";

const EventLogs = ({ targetId, targetType, searchEnabled }) => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const [selectedLogDetails, setSelectedLogDetails] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [isShowingLogDetails, setIsShowingLogDetails] = useState(false);
  const [offsetArr, setOffsetArr] = useState([0, 1, 2]);
  const [searchTerm, setSearchTerm] = useState("");
  const eventLogsQuery = useEventLogs(
    activePage - 1,
    searchTerm,
    targetId,
    targetType
  );
  const eventLogs = eventLogsQuery.data?.eventLogs;
  const pages = eventLogsQuery.data?.pages;

  useEffect(() => {
    switch (activePage) {
      case 1:
        setOffsetArr([0, 1, 2]);
        break;
      case pages:
        setOffsetArr([-2, -1, 0]);
        break;
      default:
        setOffsetArr([-1, 0, 1]);
    }
  }, [activePage]);

  const navigateToTargetId = (targetType, id) => {
    switch (targetType) {
      case "user":
        navigate("/user/" + id);
        break;
      case "school":
        navigate("/school-settings/" + id);
        break;
      default:
    }
  };

  const renderTopPagination = () => {
    if (!eventLogs || eventLogs.length === 0) {
      return <Alert>{translate("no_search_results")}</Alert>;
    }

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => {
            if (activePage !== 1) {
              setActivePage((curr) => curr - 1);
            }
          }}
        />
        <Pagination.Item onClick={() => setActivePage(1)}>{1}</Pagination.Item>
        <Pagination.Ellipsis />
        {offsetArr.map((offset) => {
          if (activePage + offset > pages || activePage + offset < 1) {
            return;
          }

          return (
            <Pagination.Item
              key={offset}
              active={activePage === activePage + offset}
              onClick={() => setActivePage(activePage + offset)}
            >
              {activePage + offset}
            </Pagination.Item>
          );
        })}

        <Pagination.Ellipsis />
        <Pagination.Item onClick={() => setActivePage(pages)}>
          {pages}
        </Pagination.Item>
        <Pagination.Next
          onClick={() => {
            if (activePage !== pages) {
              setActivePage((curr) => curr + 1);
            }
          }}
        />
      </Pagination>
    );
  };

  const renderBottomPagination = () => {
    if (!eventLogs || eventLogsQuery.data.count === 0) {
      return;
    }

    return (
      <Pagination>
        <Pagination.Prev
          onClick={() => {
            if (activePage !== 1) {
              setActivePage((curr) => curr - 1);
            }
          }}
        />
        <Pagination.Next
          onClick={() => {
            if (activePage !== pages) {
              setActivePage((curr) => curr + 1);
            }
          }}
        />
        {activePage === pages && (
          <span className="my-auto mx-2">{translate("last_page")}</span>
        )}
      </Pagination>
    );
  };
  const renderLogDetailsModal = () => {
    if (selectedLogDetails) {
      return (
        <Modal
          className="event-log-modal"
          show={isShowingLogDetails}
          onHide={() => setIsShowingLogDetails(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{translate("log_details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {Object.entries(selectedLogDetails).map((entry) => {
                const [key, value] = entry;

                if (typeof value === "object") {
                  return (
                    <li key={key}>
                      <strong>{key}: </strong>&nbsp;
                      {JSON.stringify(value)}
                    </li>
                  );
                }

                return (
                  <li key={key}>
                    <strong>{key}: </strong>&nbsp;
                    {key === "targetId" ? (
                      <u
                        onClick={() =>
                          navigateToTargetId(
                            selectedLogDetails.targetType,
                            value
                          )
                        }
                        className="touchable"
                      >
                        {value}
                      </u>
                    ) : (
                      value
                    )}
                  </li>
                );
              })}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setIsShowingLogDetails(false)}>
              {translate("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  };

  const renderSearch = () => {
    if (searchEnabled) {
      return (
        <div id="event-log-search-container">
          <EventSearch
            handleSearch={(searchTermParam) => {
              setActivePage(1);
              if (searchTermParam === searchTerm) {
                eventLogsQuery.refetch();
              } else {
                setSearchTerm(searchTermParam);
              }
            }}
          />
        </div>
      );
    }
  };

  return (
    <div>
      {renderSearch()}
      <div className="event-logs-results white-box">
        {renderLogDetailsModal()}
        <div className="d-flex gap-3">
          {renderTopPagination()}
          {eventLogsQuery.isFetching && <Spinner />}
        </div>
        <hr />
        <div className="custom-table full-width">
          <div className="custom-table-header">
            <div className="ct-column-name">{translate("user")}</div>
            <div className="ct-column-name">{translate("event")}</div>
            <div className="ct-column-name">{translate("target")}</div>
            <div className="ct-column-name">{translate("date")}</div>
            <div className="ct-column-name"></div>
          </div>
          <div className="ct-table-body">
            {eventLogs?.map((log) => {
              return (
                <div
                  key={log.id}
                  className="ct-table-row touchable"
                  onClick={() => {
                    setSelectedLogDetails(log);
                    setIsShowingLogDetails(true);
                  }}
                >
                  <div className="ct-table-body-cell">
                    <strong className="ct-mobile-only">
                      {translate("user")}:
                    </strong>
                    &nbsp;
                    {log.triggeringUserId}
                  </div>
                  <div className="ct-table-body-cell">
                    <strong className="ct-mobile-only">
                      {translate("event")}:
                    </strong>
                    &nbsp;
                    {log.eventType}
                  </div>
                  <div className="ct-table-body-cell">
                    <strong className="ct-mobile-only">
                      {translate("target")}:
                    </strong>
                    &nbsp;
                    {log.targetId} ({log.targetType})
                  </div>
                  <div className="ct-table-body-cell">
                    {DateFormat.dateTime(log.createdAt)}
                  </div>
                  <div className="ct-table-body-cell">
                    <span className="ct-desktop-only">
                      <FiChevronRight />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {renderBottomPagination()}
      </div>
    </div>
  );
};

const EventSearch = ({ handleSearch }) => {
  const { translate } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    handleSearch(searchTerm);
  };
  return (
    <Form onSubmit={submitHandler} className="fade-in">
      <Form.Group>
        <InputGroup>
          <InputGroup.Text>
            <IoSearch />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder={translate("search") + "..."}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <InputGroup>
            <Button onClick={submitHandler}>{translate("search")}</Button>
          </InputGroup>
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

export default EventLogs;
