import News from '../../feature/News';
import ClearCaches from '../../feature/admin/ClearCaches';
import SchoolSearch from '../../feature/admin/SchoolSearch';

const AdminDashboardPage = () => {
  return (
    <div className="page-container page-gutter max-width-container">
      <News />
      <ClearCaches />
      <br />
      <SchoolSearch />
    </div>
  );
};

export default AdminDashboardPage;

