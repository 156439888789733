import { useState } from "react";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import classNames from "classnames";
import ControlLabel from "react-bootstrap/FormLabel";

import Translated from "../../feature/Translated";
import InvoiceList from "../../feature/admin/InvoiceList";
import { useTranslation } from "../../feature/TranslationProvider";

const InvoicingListPage = () => {
  const { translate } = useTranslation();
  const [filters, setFilters] = useState(null);
  const [editedFilters, setEditedFilters] = useState({
    startDate: null,
    endDate: null,
    schoolId: null,
    openState: null,
  });

  const handleFilterChange = (e) => {
    setEditedFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const applyEditedFilters = () => {
    setFilters(editedFilters);
  };

  const makeFormGroup = (label, fieldName, classes) => {
    classes = classNames("settings-field", classes);
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          className={classes}
          name={fieldName}
          placeholder={label}
          value={editedFilters[fieldName] ? editedFilters[fieldName] : ""}
          onChange={handleFilterChange}
        />
      </FormGroup>
    );
  };

  return (
    <div className="page-container max-width-container">
      <h2>
        <Translated translationKey="invoicing-list" />
      </h2>
      <div className="blue-box">
        {makeFormGroup(translate("start_date"), "startDate")}
        {makeFormGroup(translate("end_date"), "endDate")}
        <Button onClick={applyEditedFilters}>
          <Translated translationKey="apply" />
        </Button>
      </div>
      <InvoiceList filters={filters} />
    </div>
  );
};

export default InvoicingListPage;
