import "./styles/style.scss";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import AppAdmin from "./components/AppAdmin";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TranslationProvider from "./components/feature/TranslationProvider";
import Logger from "./utils/logger";
import { clientSideAdmin } from "./localizations/clientSideAdmin";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./api-new";
import GlobalContextProvider from "./context/GlobalContext";
import UserContextProvider from "./context/UserDataContext";

Logger.initialize();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
      <QueryClientProvider client={queryClient}>
        <TranslationProvider injectedLocalizations={clientSideAdmin}>
          <GlobalContextProvider>
            <UserContextProvider>
              <Router>
                <Routes>
                  <Route path="/*" element={<AppAdmin />} />
                </Routes>
              </Router>
            </UserContextProvider>
          </GlobalContextProvider>
        </TranslationProvider>
      </QueryClientProvider>
  </StrictMode>
);
