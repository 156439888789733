import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";

import DropdownInput from "../DropdownInput";
import { useTranslation } from "../TranslationProvider";
import Spinner from "../Spinner";
import { useAddMaterialLicense } from "../../../api-new";

const DEFAULT_VALIDITY_PERIOD_DAYS = 14;

const AddMaterialModal = ({
  userId,
  refetchLicenses,
  isAddLicenseModal,
  setIsAddLicenseModal,
  materialTypes,
  materialIds,
}) => {
  const { translate } = useTranslation();
  const [materialType, setMaterialType] = useState(materialTypes[0]);
  const [materialId, setMaterialId] = useState(materialIds[0]);
  const [validForDays, setValidForDays] = useState(
    DEFAULT_VALIDITY_PERIOD_DAYS
  );
  const [isSaving, setIsSaving] = useState(null);
  const materialLicenseMutation = useAddMaterialLicense();

  const addLicense = async () => {
    materialLicenseMutation.mutate(
      {
        userId,
        data: {
          materialType,
          materialId,
          validForDays,
        },
      },
      {
        onSuccess: () => {
          refetchLicenses();
          setIsAddLicenseModal(false);
        },
      }
    );
  };

  return (
    <Modal show={isAddLicenseModal} onHide={() => setIsAddLicenseModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("license_information")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{translate("type") + ":"}</Form.Label>
        <DropdownInput
          listener={(value) => setMaterialType(value)}
          value={materialType}
          title={materialType}
          items={materialTypes.map((materialType) => [
            materialType,
            materialType,
          ])}
        />
        <Form.Label>{translate("id") + ":"}</Form.Label>
        <DropdownInput
          listener={(value) => setMaterialId(value)}
          value={materialId}
          title={materialId}
          items={materialIds.map((materialId) => [materialId, materialId])}
        />
        <Form.Group className={"mb-3"}>
          <Form.Label>{translate("duration_days")}</Form.Label>
          <Form.Control
            type="number"
            name="input"
            placeholder={translate("duration_days")}
            value={validForDays}
            onChange={(e) =>
              setValidForDays(parseInt(e.target.value, 10) || "")
            }
          />
        </Form.Group>
        {materialLicenseMutation.isError && (
          <Alert>{translate(materialLicenseMutation.error.message)}</Alert>
        )}
        {isSaving && <Spinner />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={addLicense}>{translate("add")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMaterialModal;
