import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import AppRoutesAdmin from "./AppRoutesAdmin";
import AdminHeader from "./feature/admin/AdminHeader";
import Translated from "./feature/Translated";
import Logger from "../utils/logger";
import WebFont from "webfontloader";
import Footer from "./feature/Footer";
import { useUserData } from "../context/UserDataContext";
import { useGlobalData } from "../context/GlobalContext";

const AppAdmin = () => {
  const { token, isLoadingUserData } = useUserData();
  const { hasMaintenance, setHasMaintenance, hasConnection, setHasConnection } =
    useGlobalData();
  const location = useLocation();

  useEffect(() => {
    if (token) {
      Logger.setUserData({ token });
    }
    document.body.className = "nmd-bg";

    // Needs to be configured at CSS also (_variables)!
    WebFont.load({
      google: {
        families: ["Ubuntu:300,400,700"],
        urls: "https://fonts.googleapis.com/css?",
      },
    });
  }, []);

  const showModal = !hasConnection || hasMaintenance;
  let modalMessage;
  let modalAction;
  if (showModal) {
    if (!hasConnection) {
      modalMessage = "network-error";
      modalAction = () => setHasConnection(true);
    } else {
      modalMessage = "maintenance";
      modalAction = () => setHasMaintenance(false);
    }
  }

  if (isLoadingUserData) {
    return null;
  }

  return (
    <div>
      <div className="body-container-wrapper">
        <div className="body-container">
          <AdminHeader className="header-container" location={location} />
          <AppRoutesAdmin />
          <Footer dropdownLangs={["fi", "en"]} />
        </div>
        <Modal show={showModal}>
          <Modal.Body>
            {modalMessage && <Translated translationKey={modalMessage} />}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={modalAction}>
              <Translated translationKey="close" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AppAdmin;
