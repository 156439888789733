import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";

import Spinner from "./../../feature/Spinner";
import Translated from "../Translated";
import { useAddSchoolComment, useGetSchoolComments } from "../../../api-new";
import { useTranslation } from "../TranslationProvider";

const SchoolComments = ({ schoolId }) => {
  const { translate } = useTranslation();
  const [comment, setComment] = useState("");
  const commentsQuery = useGetSchoolComments(schoolId);
  const commentMutation = useAddSchoolComment();
  const [customError, setCustomError] = useState(null);

  const onSubmit = (e) => {
    console.log("SUBMIT");
    if (e) {
      e.preventDefault();
    }

    console.log(1);
    if (comment.trim().length === 0) {
      setCustomError("invalid-input");
      return;
    }

    commentMutation.mutate(
      { schoolId, comment },
      {
        onSuccess: () => {
          setComment("");
          commentsQuery.refetch();
        },
      }
    );
  };

  const renderComments = () => {
    if (commentsQuery.isPending) {
      return <Spinner />;
    }

    if (commentsQuery.isError) {
      return (
        <Alert>
          <Translated translationKey={commentsQuery.error.message} />
        </Alert>
      );
    }

    const comments = commentsQuery.data;

    if (!comments || comments.length === 0) {
      return null;
    }

    const commentElems = [];
    comments.forEach((comment) => {
      commentElems.push(
        <p key={comment.id}>
          <span className="semi-transparent">
            {!comment.user.userName || comment.user.userName.length === 0 ? (
              <Translated translationKey="not_available" />
            ) : (
              comment.user.userName
            )}
            , {comment.localDate}
          </span>
          <br />
          {comment.comment}
        </p>
      );
    });
    return commentElems;
  };

  return (
    <div className="school-list">
      <div id="user-search-container">
        <Form onSubmit={onSubmit} className="fade-in">
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Control
                type="text"
                name="newComment"
                placeholder={translate("add_comment")}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onFocus={() => {
                  setCustomError(null);
                  commentMutation.reset();
                }}
              />
              <InputGroup>
                <Button
                  className="top-margin-small"
                  id="add-comment-button"
                  type="submit"
                >
                  {translate("add_comment")}
                  {commentMutation.isPending && <Spinner />}
                </Button>
              </InputGroup>
              {customError && (
                <Alert>
                  <Translated translationKey={customError} />
                </Alert>
              )}
              {commentMutation.isError && (
                <Alert>{commentMutation.error.message}</Alert>
              )}
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <br />
      {renderComments()}
    </div>
  );
};

export default SchoolComments;
