import { useState } from "react";
import Button from "react-bootstrap/Button";

import Reports from "./Reports";
import BookModalLink from "../BookModalLink";
import TextInput from "../TextInput";
import Translated from "../Translated";
import { useAddBookReference } from "../../../api-new";

const LiteralQuestionAdmin = ({
  lang,
  question,
  category,
  refetchQuestions,
}) => {
  const [bookReference, setBookReference] = useState("");
  const bookReferenceMutation = useAddBookReference();

  const saveReference = () => {
    bookReferenceMutation.mutate(
      {
        questionType: "literal",
        questionId: question.id,
        category,
        referenceId: bookReference,
      },
      {
        onSuccess: () => {
          refetchQuestions();
        },
      }
    );
  };

  const renderBookContent = () => {
    if (question.bookReferenceIds.length === 0) {
      return (
        <div>
          <TextInput
            listener={(value) => setBookReference(value)}
            value={bookReference}
            placeholder="book reference id"
          />
          <Button disabled={bookReference.length < 1} onClick={saveReference}>
            <Translated translationKey="save" />
          </Button>
          {bookReferenceMutation.isError && (
            <span>
              <Translated
                translationKey={bookReferenceMutation.error.message}
              />
            </span>
          )}
        </div>
      );
    }

    return question.bookReferenceIds.map((refId) => (
      <BookModalLink lang={lang} contentId={refId} showId key={refId} />
    ));
  };

  const renderReports = () => {
    const reports = question.reports;
    return reports ? <Reports reports={reports} /> : null;
  };

  if (!question) {
    return null;
  }
  return (
    <div className="test-result-literal-wrapper">
      <h5>
        {question.id}. {question.question}
      </h5>
      {question.imageUrl && (
        <img src={question.imageUrl} className="practise-literal-image" />
      )}
      <div className={question.correctAnswer === "a" ? "underlined" : ""}>
        {question.options.a}
      </div>
      <div className={question.correctAnswer === "b" ? "underlined" : ""}>
        {question.options.b}
      </div>
      <div className={question.correctAnswer === "c" ? "underlined" : ""}>
        {question.options.c}
      </div>
      {renderBookContent()}
      {renderReports()}
    </div>
  );
};

export default LiteralQuestionAdmin;
