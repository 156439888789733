import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Spinner from "../Spinner";
import Translated from "../Translated";
import EmailUtil from "../../../utils/EmailUtil";
import { useTranslation } from "../TranslationProvider";
import { useEmailEvents } from "../../../api-new";

const AdminSearchEmailEvents = ({ propsEmail = null }) => {
  const { translate } = useTranslation();
  const { email: paramsEmail } = useParams();
  const inputTermRef = useRef(propsEmail || paramsEmail || "");
  const [customError, setCustomError] = useState(null);
  const [email, setEmail] = useState(inputTermRef.current);
  const eventsQuery = useEmailEvents(email);

  const inputChangeHandler = (e) => {
    inputTermRef.current = e.target.value.trim();
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setCustomError(null);

    if (!EmailUtil.isEmail(inputTermRef.current)) {
      setCustomError("invalid-input");
      return;
    }

    setEmail(inputTermRef.current);
  };

  const renderResultsContent = () => {
    if (eventsQuery.isFetching) {
      return (
        <div className="centered large-top-margin">
          <Spinner />
        </div>
      );
    }

    if (eventsQuery.isError || customError) {
      return (
        <div className="large-top-margin">
          <Alert>
            <Translated
              translationKey={
                eventsQuery.isError ? eventsQuery.error.message : customError
              }
            />
          </Alert>
        </div>
      );
    }

    if (!eventsQuery.data) {
      return;
    }

    const emailEvents = eventsQuery.data.events;
    const eventsRange = eventsQuery.data.range;

    if (emailEvents.length === 0) {
      return (
        <Alert>
          <Translated translationKey="no_search_results" />
        </Alert>
      );
    }

    return (
      <div className="admin-ee-results-container blue-box">
        <h4>
          <Translated translationKey="email_to" />: {emailEvents[0].email}
        </h4>
        <div>
          ({eventsRange && eventsRange.startDate + " - " + eventsRange.endDate})
        </div>

        {emailEvents.map((email) => (
          <div key={email.date} className="ee-result">
            <strong>
              <p>{email.subject}</p>
            </strong>
            <p>{`${translate("email_from")}: ${email.from}`}</p>

            {email.reason && (
              <div className="ee-reason">
                <p>Reason: {email.reason}</p>
                <span className={`ee_status_${email.event}`}>
                  <p>Status: {email.event}</p>
                </span>
              </div>
            )}

            {!email.reason && (
              <span className={`ee_status_${email.event}`}>
                <p>Status: {email.event}</p>
              </span>
            )}

            <p className="ee-date">{email.localDate || "-"}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {!propsEmail && (
        <div className="admin-ee-search blue-box">
          <h2>{translate("search_for_email_events")}:</h2>
          <form onSubmit={formSubmit}>
            <input
              onChange={inputChangeHandler}
              type="text"
              id="email"
              placeholder={translate("email")}
            />

            <Button
              disabled={eventsQuery.isFetching}
              id="search-button"
              type="submit"
              className="btn btn-primary"
            >
              <Translated translationKey="search_do" />
            </Button>
          </form>
        </div>
      )}
      {renderResultsContent()}
    </div>
  );
};

export default AdminSearchEmailEvents;
