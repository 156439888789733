import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import LiteralQuestionAdmin from "../../feature/admin/LiteralQuestionAdmin";
import Button from "react-bootstrap/Button";
import Spinner from "../../feature/Spinner";
import TextInput from "../../feature/TextInput";
import Alert from "react-bootstrap/Alert";
import Translated from "../../feature/Translated";
import DropdownInput from "../../feature/DropdownInput";
import { useListLiteralQuestions } from "../../../api-new";
import { useTranslation } from "../../feature/TranslationProvider";

const SORTING_VALUES = [
  [null, "none"],
  ["reports", "reports"],
  ["bookRefs", "book_references"],
];

const LANGUAGES = Object.keys(LANGUAGE_SELECTIONS).map((lang) => [
  lang,
  LANGUAGE_SELECTIONS[lang],
]);

const AdminLiteralsPage = () => {
  const { translate } = useTranslation();
  const [language, setLanguage] = useState("fi");
  const [category, setCategory] = useState("b");
  const [id, setId] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [queryInputs, setQueryInputs] = useState({ language, category, id });
  const literalsQuery = useListLiteralQuestions(
    queryInputs.category,
    queryInputs.language,
    queryInputs.id
  );

  const filterListener = (filter, value) => {
    switch (filter) {
      case "language":
        setLanguage(value);
        break;
      case "category":
        setCategory(value);
        break;
      case "id":
        setId(value);
        break;
    }
  };

  const initFilters = () => {
    setQueryInputs({ language, category, id });
  };

  const sortListener = (value) => {
    setSortBy(value);
  };

  const sortQuestionsOnId = (question1, question2) => {
    const id1 = question1.id;
    const id2 = question2.id;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  };

  const sortQuestionsOnReportsDesc = (question1, question2) => {
    const len1 = question1.reports ? question1.reports.length : 0;
    const len2 = question2.reports ? question2.reports.length : 0;
    if (len1 === len2) {
      return 0;
    }
    return len1 > len2 ? -1 : 1;
  };

  const sortQuestionsOnBookRefs = (question1, question2) => {
    const id1 = question1.bookReferenceIds
      ? question1.bookReferenceIds[0]
      : null;
    const id2 = question2.bookReferenceIds
      ? question2.bookReferenceIds[0]
      : null;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  };

  const renderLiterals = useMemo(() => {
    if (literalsQuery.isFetching) {
      return <Spinner />;
    }
    if (literalsQuery.isError) {
      return (
        <div>
          <Alert>
            <Translated translationKey={literalsQuery.error.message} />
          </Alert>
        </div>
      );
    }

    const questions = literalsQuery.data?.questions;

    if (!questions) {
      return;
    }

    switch (sortBy) {
      case "reports":
        questions.sort(sortQuestionsOnReportsDesc);
        break;
      case "bookRefs":
        questions.sort(sortQuestionsOnBookRefs);
        break;
      default:
        questions.sort(sortQuestionsOnId);
    }

    return questions.map((literal) => (
      <LiteralQuestionAdmin
        key={literal.id}
        lang={language}
        question={literal}
        category={category}
        refetchQuestions={literalsQuery.refetch}
      />
    ));
  }, [
    literalsQuery.data,
    literalsQuery.isFetching,
    literalsQuery.isError,
    literalsQuery.error,
    sortBy,
  ]);

  return (
    <div className="page-container page-gutter max-width-container">
      <DropdownInput
        listener={(value) => filterListener("language", value)}
        value={language}
        title={LANGUAGES.find((lang) => lang[0] === language)[1]}
        items={LANGUAGES}
      />
      <TextInput
        listener={(value) => filterListener("category", value)}
        value={category}
        placeholder="category"
        autoComplete="off"
      />
      <TextInput
        listener={(value) => filterListener("id", value)}
        value={id}
        placeholder="id"
        autoComplete="off"
      />
      <DropdownInput
        listener={(value) => sortListener(value)}
        value={sortBy}
        title="sorting"
        items={SORTING_VALUES}
      />
      <Button id="list-literals-button" onClick={initFilters}>
        {translate("search_do")}
      </Button>
      {renderLiterals}
    </div>
  );
};

export default AdminLiteralsPage;
