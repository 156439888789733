import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";

import Translated from "./../Translated";
import Spinner from "./../../feature/Spinner";
import { useTranslation } from "../TranslationProvider";
import { useAddSchool } from "../../../api-new";

const SchoolAdd = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const schoolMutation = useAddSchool();

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    schoolMutation.mutate({ schoolId: id, schoolEmail: email });
  };

  const renderSchoolLink = () => {
    if (!schoolMutation.isSuccess) {
      return null;
    }

    return (
      <div className="top-margin-small">
        {schoolMutation.data.school.id}
        &nbsp;
        <Button
          onClick={() => {
            navigate(`/school-settings/${schoolMutation.data.school.id}`);
          }}
        >
          <Translated translationKey="edit" />
        </Button>
      </div>
    );
  };

  const renderError = () => {
    if (schoolMutation.isError) {
      return <Alert>{schoolMutation.error.message}</Alert>;
    }
    return null;
  };

  if (schoolMutation.isPending) {
    return <Spinner />;
  }

  return (
    <div className="school-list">
      {renderError()}
      <div id="user-search-container">
        <Form onSubmit={onSubmit} className="fade-in">
          <Form.Group>
            <InputGroup>
              <Form.Control
                type="text"
                name="school_id"
                placeholder={translate("id")}
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <Form.Control
                type="text"
                name="school_email"
                placeholder={translate("email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
            </InputGroup>
            <InputGroup>
              <Button id="school-add-button" onClick={onSubmit}>
                {translate("add_do")}
              </Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      {renderSchoolLink()}
    </div>
  );
};

export default SchoolAdd;
