import {  useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { IoAlertCircle } from "react-icons/io5";

import PreloadMiddlePage from "../../pages/PreloadMiddlePage";
import EditUser from "../../feature/EditUser";
import UserCategoryStatus from "../../feature/UserCategoryStatus";
import TestResultsList from "../../feature/results/TestResultsList";
import Translated from "../../feature/Translated";
import UserBookStatuses from "../../feature/UserBookStatuses";
import { Roles, fillsRole } from "../../../data/roles";
import AdminSearchEmailEvents from "../../feature/admin/AdminSearchEmailEvents";
import AdminListUserLicenses from "../../feature/admin/AdminListUserLicenses";
import SingleItemAccordion from "../../feature/SingleItemAccordion";
import SubscriptionPeriods from "../../feature/admin/SubscriptionPeriods";
import LoginLogs from "../../feature/admin/LoginLogs";
import AdminListWebshopOrders from "../../feature/admin/AdminListWebshopOrders";
import UserComments from "../../feature/admin/UserComments";
import EventLogs from "../../feature/admin/EventLogs";
import {
  invalidateQueries,
  QUERY_KEY,
  useChangeEmail,
  useDisconnectEmail,
  useSaveProfile,
  useUserProfile,
  useRemoveEmailPassword,
} from "../../../api-new";

const EditUserPage = () => {
  const { userId } = useParams();
  const userProfileQuery = useUserProfile(userId);
  const saveProfileMutation = useSaveProfile();
  const changeEmailMutation = useChangeEmail();
  const disconnectEmailMutation = useDisconnectEmail();
  const removeEmailPassowrdMutation = useRemoveEmailPassword();
  const adminErrors = {
    changeEmailError: changeEmailMutation.error?.message || null,
    disconnectEmailError: disconnectEmailMutation.error?.message || null,
    removeEmailPasswordError:
      removeEmailPassowrdMutation.error?.message || null,
  };

  const saveUserData = (userData, callback) => {
    saveProfileMutation.mutate(userData, {
      onSuccess: () => {
        invalidateQueries(QUERY_KEY.USER_PROFILE, userId);
        if (callback) {
          callback();
        }
      },
    });
  };

  const changeUserEmail = (email, callback) => {
    if (email === userProfile.userData.email) {
      callback();
      return;
    }

    changeEmailMutation.mutate(
      { userId: userProfile.userData.id, email },
      {
        onSuccess: () => {
          invalidateQueries(QUERY_KEY.USER_PROFILE, userProfile.userData.id);
          if (callback) {
            callback();
          }
        },
      }
    );
  };

  const disconnectEmail = () => {
    disconnectEmailMutation.mutate(
      { userId: userProfile.userData.id },
      {
        onSuccess: () => {
          invalidateQueries(QUERY_KEY.USER_PROFILE, userProfile.userData.id);
        },
      }
    );
  };

  const removeEmailPassword = () => {
    removeEmailPassowrdMutation.mutate(
      { userId: userProfile.userData.id },
      {
        onSuccess: () => {
          invalidateQueries(QUERY_KEY.USER_PROFILE, userProfile.userData.id);
        },
      }
    );
  };

  const renderCategoryStatus = () => {
    const categoryStatuses = userProfile.status.categoryStatus;
    if (categoryStatuses.length === 0) {
      return (
        <Alert>
          <IoAlertCircle />
          &nbsp;&nbsp;&nbsp;
          <Translated translationKey="no_exercises_done" />
        </Alert>
      );
    }
    return categoryStatuses.map((catStatus) => {
      return (
        <UserCategoryStatus
          userId={userProfile.userData.id}
          category={catStatus.category}
          acceptedAmount={catStatus.acceptedTestAmount}
          totalAcceptedRequired={catStatus.acceptedTestsRequired}
          rejectedAmount={catStatus.rejectedTestAmount}
          simple={true}
          key={catStatus.category}
        />
      );
    });
  };

  const renderStatus = () => {
    const isTeacher = fillsRole(userProfile.userData.role, Roles.TEACHER);
    if (isTeacher) {
      return null;
    }
    return (
      <div>
        {renderCategoryStatus()}
        <UserBookStatuses userId={userProfile.userData.id} />
        {userProfile.status.categoryStatus.length === 0 ? null : (
          <TestResultsList userId={userProfile.userData.id} />
        )}
      </div>
    );
  };

  const renderEmailEvents = (email) => {
    return (
      <SingleItemAccordion
        title={<Translated translationKey="email_events_page" />}
        content={<AdminSearchEmailEvents propsEmail={email} />}
      />
    );
  };

  const renderEventLogs = (userId) => {
    return (
      <SingleItemAccordion
        title={<Translated translationKey="event_logs" />}
        content={<EventLogs targetId={userId} targetType={"user"} />}
      />
    );
  };

  if (userProfileQuery.isPending) {
    return <PreloadMiddlePage />;
  }

  if (userProfileQuery.isError) {
    return (
      <Alert id="edit-student-error">
        <Translated translationKey={userProfileQuery.error.message} />
      </Alert>
    );
  }

  const userProfile = userProfileQuery.data;
  const isTeacher = fillsRole(userProfile.userData.role, Roles.TEACHER);
  const hasEmail = !!userProfile.userData.email;

  return (
    <div id="edit-student-page" className="page-container max-width-container">
      <div>
        {isTeacher ? (
          <h2>
            <Translated translationKey="teacher" />
          </h2>
        ) : null}
        <EditUser
          isAdminView={true}
          adminErrors={adminErrors}
          resetAdminError={(key) => {
            switch (key) {
              case "changeEmailError":
                changeEmailMutation.reset();
                break;
            }
          }}
          profile={userProfile}
          saveUserMethod={saveUserData}
          isSaving={
            saveProfileMutation.isPending || changeEmailMutation.isPending
          }
          changeUserEmail={changeUserEmail}
          disconnectEmail={disconnectEmail}
          removeEmailPassword={removeEmailPassword}
        />
        <SubscriptionPeriods userId={userProfile.userData.id} />
        {userProfile.userData.activationDate && renderStatus()}
        <AdminListUserLicenses userId={userProfile.userData.id} />
        <AdminListWebshopOrders userEmail={userProfile.userData.email} />
        {hasEmail && renderEmailEvents(userProfile.userData.email)}
        <LoginLogs userId={userProfile.userData.id} />
        {renderEventLogs(userProfile.userData.id)}
        <UserComments userId={userProfile.userData.id} />
      </div>
    </div>
  );
};

export default EditUserPage;
