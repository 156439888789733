import UserSearch from "../../feature/admin/UserSearch";

const AdminSearchUsersPage = () => {
  return (
    <div className="page-container page-gutter max-width-container">
      <UserSearch />
    </div>
  );
};

export default AdminSearchUsersPage;
