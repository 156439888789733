import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { IoReload } from "react-icons/io5";

import Translated from "../../feature/Translated";
import { useClearCaches } from "../../../api-new";
import { Fade } from "react-bootstrap";

const ClearCaches = () => {
  const clearCachesMutation = useClearCaches();

  const clearCaches = () => {
    clearCachesMutation.mutate(null, {
      onSuccess: () => {
        setTimeout(clearCachesMutation.reset, 3000);
      },
    });
  };

  return (
    <div>
      <Button onClick={clearCaches}>
        <IoReload />
        <Translated translationKey="clear_caches" />
      </Button>
      <Fade in={clearCachesMutation.isSuccess}>
        <div>
          <Alert>{JSON.stringify(clearCachesMutation.data)}</Alert>
        </div>
      </Fade>
    </div>
  );
};

export default ClearCaches;
