import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import FormControl from "react-bootstrap/FormControl";

import TextInput from "../../feature/TextInput";
import Translated from "../../feature/Translated";
import Spinner from "../../feature/Spinner";
import { usePrecreateOneTimeKeys } from "../../../api-new";

const PreCreateKeysPage = () => {
  const [customError, setCustomError] = useState(null);
  const oneTimeKeysMutation = usePrecreateOneTimeKeys();
  const amountPerSchoolRef = useRef("");
  const schoolIdsStrRef = useRef("");

  const postCreate = (schoolIdsStr, keyAmountPerSchool) => {
    let amount = parseInt(keyAmountPerSchool);
    if (isNaN(amount) || amount < 1) {
      setCustomError("invalid_key_amount");
      return;
    }
    if (!schoolIdsStr) {
      setCustomError("invalid_school_ids");
      return;
    }
    const cleaned = schoolIdsStr.replace(/\s/g, "");
    const schoolIdsArr = cleaned.split(",");
    if (schoolIdsArr.length < 1) {
      setCustomError("invalid_school_ids");
      return;
    }

    setCustomError(null);

    oneTimeKeysMutation.mutate({
      schoolIdsArr,
      amountPerSchool: parseInt(keyAmountPerSchool),
    });
  };

  const renderAmountInput = () => {
    return (
      <div className="medium-top-margin">
        <Translated translationKey="amount_per_school" />
        <TextInput
          listener={(value) => {
            amountPerSchoolRef.current = value;
          }}
        />
      </div>
    );
  };

  const renderSchoolIdsInput = () => {
    return (
      <div className="top-margin">
        <Translated translationKey="school_ids" />
        <FormControl
          componentclass="textarea"
          name="schoolIds"
          onChange={(e) => {
            schoolIdsStrRef.current = e.target.value;
          }}
        />
      </div>
    );
  };

  const renderError = () => {
    if (customError || oneTimeKeysMutation.isError) {
      return (
        <Alert className="top-margin fade-in">
          {customError && <Translated translationKey={customError} />}
          {oneTimeKeysMutation.isError && (
            <Translated translationKey={oneTimeKeysMutation.error.message} />
          )}
        </Alert>
      );
    }
    return null;
  };

  const renderLoading = () => {
    return (
      oneTimeKeysMutation.isPending && (
        <div className="top-margin">
          <Spinner />
        </div>
      )
    );
  };

  const renderOrders = () => {
    if (!oneTimeKeysMutation.isSuccess) {
      return null;
    }
    const createdOrders = oneTimeKeysMutation.data.orders;

    return (
      <div>
        {createdOrders.map((order) => (
          <div key={order.id}>
            {order.id} {order.schoolId} {order.deliveryAddress}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      id="school-settings-page"
      className="page-container max-width-container"
    >
      <h2>
        <Translated translationKey="pre_create_one_time_keys" />
      </h2>
      {renderAmountInput()}
      {renderSchoolIdsInput()}
      <Button
        className="top-margin"
        onClick={() =>
          postCreate(schoolIdsStrRef.current, amountPerSchoolRef.current)
        }
      >
        <Translated translationKey="create" />
      </Button>
      {renderLoading()}
      {renderError()}
      {renderOrders()}
    </div>
  );
};

export default PreCreateKeysPage;
