import { useParams } from "react-router-dom";

import Translated from "../../feature/Translated";
import InvoiceList from "../../feature/admin/InvoiceList";
import { useUserData } from "../../../context/UserDataContext";

const SchoolInvoicesPage = () => {
  const { schoolId: ctxSchoolId } = useUserData();
  const params = useParams();
  const schoolId = params.schoolId || ctxSchoolId;

  return (
    <div className="page-container max-width-container">
      <h2>
        <Translated translationKey="invoices" />
      </h2>
      <h3>{schoolId}</h3>
      <InvoiceList filters={{ schoolId }} immediate={true} />
    </div>
  );
};

export default SchoolInvoicesPage;
