import { useEffect } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import AdminSearchUsersPage from "./pages/admin/AdminSearchUsersPage";
import AdminSchoolAddPage from "./pages/admin/AdminSchoolAddPage";
import AdminLoginAsUserPage from "./pages/admin/AdminLoginAsUserPage";
import AdminSchoolSettingsPage from "./pages/admin/AdminSchoolSettingsPage";
import AdminPractiseLimitsPage from "./pages/admin/AdminPractiseLimitsPage";
import AdminLoginPage from "./pages/admin/AdminLoginPage";
import OrdersPage from "./pages/OrdersPage";
import AdminListLiteralsPage from "./pages/admin/AdminListLiteralsPage";
import AdminListImagesPage from "./pages/admin/AdminListImagesPage";
import NotFoundPage from "./pages/NotFoundPage";
import { Roles, fillsRole } from "../data/roles";
import BookSimplePage from "./pages/BookSimplePage";
import BookSelectionPage from "./pages/BookSelectionPage";
import PreCreateKeysPage from "./pages/admin/PreCreateKeysPage";
import InvoicingPage from "./pages/admin/InvoicingPage";
import InvoicingListPage from "./pages/admin/InvoicingListPage";
import SchoolInvoicesPage from "./pages/admin/SchoolInvoicesPage";
import AdminWebshopOrdersPage from "./pages/admin/AdminWebshopOrdersPage";
import TeachersListPage from "./pages/TeachersListPage";
import AdminEditUserPage from "./pages/admin/AdminEditUserPage";
import AdminSearchEmailEventsPage from "./pages/admin/AdminSearchEmailEventsPage";
import AdminEventLogsPage from "./pages/admin/AdminEventLogsPage";
import { useUserData } from "../context/UserDataContext";

const RequireLoginIndex = ({ children }) => {
  const { token, role } = useUserData();
  const isAdminLoggedIn = token !== null && fillsRole(role, Roles.ADMIN);

  return isAdminLoggedIn ? children : <Navigate to={"login"} />;
};

const RequireAdminLogin = ({ children }) => {
  const { token, role } = useUserData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token || !fillsRole(role, Roles.ADMIN)) {
      return navigate("/login", { state: { redirect: location } });
    }
  }, [token, navigate, location]);

  return token ? children : null;
};

const AppRoutesAdmin = () => {
  const { token } = useUserData();

  const renderIndexRoute = (path, Component) => (
    <Route
      path={path}
      element={
        <RequireLoginIndex>
          <Component />
        </RequireLoginIndex>
      }
    />
  );

  const renderRequireAdminLoginRoute = (path, Component) => (
    <Route
      path={path}
      element={
        <RequireAdminLogin>
          <Component />
        </RequireAdminLogin>
      }
    />
  );
  return (
    <Routes>
      {renderIndexRoute("/", AdminDashboardPage)}
      <Route path="/login" element={<AdminLoginPage />} />
      {renderRequireAdminLoginRoute("/search-users", AdminSearchUsersPage)}
      {renderRequireAdminLoginRoute("/school-add/", AdminSchoolAddPage)}
      {renderRequireAdminLoginRoute("/login-as-user/", AdminLoginAsUserPage)}
      {renderRequireAdminLoginRoute(
        "/school-settings/:schoolId",
        AdminSchoolSettingsPage
      )}
      {renderRequireAdminLoginRoute(
        "/school-limits/:schoolId",
        AdminPractiseLimitsPage
      )}
      {renderRequireAdminLoginRoute("/school-orders/:schoolId", OrdersPage)}
      {renderRequireAdminLoginRoute(
        "/school-invoices/:schoolId",
        SchoolInvoicesPage
      )}
      {renderRequireAdminLoginRoute(
        "/school-teachers/:schoolId",
        TeachersListPage
      )}
      {renderRequireAdminLoginRoute("/list-literals", AdminListLiteralsPage)}
      {renderRequireAdminLoginRoute("/list-images", AdminListImagesPage)}
      {renderRequireAdminLoginRoute("/books", BookSelectionPage)}
      {renderRequireAdminLoginRoute(
        "/books/:bookId/:language/:chapterId",
        BookSimplePage
      )}
      {renderRequireAdminLoginRoute(
        "/books/:bookId/:language/",
        BookSimplePage
      )}
      {renderRequireAdminLoginRoute("/precreate", PreCreateKeysPage)}
      {renderRequireAdminLoginRoute("/invoicing", InvoicingPage)}
      {renderRequireAdminLoginRoute("/invoicing-list", InvoicingListPage)}
      {renderRequireAdminLoginRoute("/webshop-orders", AdminWebshopOrdersPage)}
      {renderRequireAdminLoginRoute("/user/:userId", AdminEditUserPage)}
      {/*Router v6 does not support optional parameters, has to be 2 seperate routes.*/}
      {renderRequireAdminLoginRoute(
        "/email-events/",
        AdminSearchEmailEventsPage
      )}
      {renderRequireAdminLoginRoute(
        "/email-events/:email",
        AdminSearchEmailEventsPage
      )}
      {renderRequireAdminLoginRoute("/event-logs", AdminEventLogsPage)}
      <Route element={NotFoundPage} />
    </Routes>
  );
};
export default AppRoutesAdmin;
