import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Nav from "react-bootstrap/Nav";

import ImageSet from "./../ImageSet";
import adminNavData from "../../../data/adminNavigationData";
import { useUserData } from "../../../context/UserDataContext";
import { useTranslation } from "../TranslationProvider";

const AdminHeader = () => {
  const { token, logout } = useUserData();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleListItemClick = (eventId) => {
    switch (eventId) {
      case "logout":
        logout(navigate);
        setIsOpen(false);
        break;
      default:
        navigate("/" + eventId);
        setIsOpen(false);
        break;
    }
  };

  const renderLogo = () => {
    return (
      <div className="header-logo">
        <ImageSet
          src="../../assets/logo-small-admin.png"
          className="fade-in"
          onClick={() => navigate("/")}
        />
      </div>
    );
  };

  const buildNavItem = (id, label) => {
    const loc = translate(label);

    const classes = "top-margin-small small-bottom-margin touchable";
    return (
      <Nav.Item
        className={classes}
        eventkey={id}
        key={id}
        onClick={() => handleListItemClick(id)}
      >
        <span className="top-margin-small">{loc}</span>
      </Nav.Item>
    );
  };

  const items = adminNavData.map((item) => {
    return buildNavItem(item.id, item.titleKey);
  });

  return (
    <div id="header-container" className="header-container nmd-bg-header">
      <div className="header-content page-gutter">
        {renderLogo()}
        <div>
          {token && (
            <Button onClick={() => setIsOpen(!isOpen)}>
              <IoMenu />
            </Button>
          )}
        </div>
      </div>
      <Collapse in={isOpen} className="full-navigation">
        <div>
          <Nav bsstyle="pills" className="nav-vertical">
            {items}
          </Nav>
        </div>
      </Collapse>
    </div>
  );
};

export default AdminHeader;
