import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import TextInput from "../../feature/TextInput";
import Translated from "../../feature/Translated";
import Spinner from "../../feature/Spinner";
import {
  useChangeOrderStatusToOk,
  useSearchWebshopOrders,
} from "../../../api-new";
import { useTranslation } from "../../feature/TranslationProvider";

const sleep = async (time) =>
  new Promise((res, rej) => {
    setTimeout(res, time);
  });

const AdminWebshopOrdersPage = () => {
  const { translate } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const changedOrderId = useRef(null);
  const changedOrderRef = useRef(null);
  const [queryTerm, setQueryTerm] = useState(searchTerm);
  const ordersQuery = useSearchWebshopOrders(queryTerm);
  const orderStatusMutation = useChangeOrderStatusToOk();

  useEffect(() => {
    if (
      ordersQuery.isSuccess &&
      changedOrderId.current &&
      changedOrderRef.current
    ) {
      changedOrderRef.current.scrollIntoView();
    }
  }, [ordersQuery.data]);

  const fetchOrders = () => {
    changedOrderId.current = null;
    if (queryTerm === searchTerm) {
      ordersQuery.refetch();
    } else {
      setQueryTerm(searchTerm);
    }
  };

  const changeOrderStatusToOk = (orderId) => {
    orderStatusMutation.mutate(
      {
        orderId,
      },
      {
        onSuccess: () => {
          changedOrderId.current = orderId;
          ordersQuery.refetch();
        },
      }
    );
  };

  const renderOrders = () => {
    const orders = ordersQuery.data.orders;

    return orders.map((order) => {
      const isOrderStatusChanged = order.id === changedOrderId.current;
      const ref = isOrderStatusChanged ? changedOrderRef : null;
      const rows = Object.keys(order).map((key) => {
        if (key === "orderDataDump") {
          return null;
        }

        if (key === "status" && order.status !== "ok") {
          return (
            <div key={key}>
              <p>
                <strong>{key}: </strong>
                {order.status}&nbsp;
              </p>
              <Button
                onClick={() => {
                  changeOrderStatusToOk(order.id);
                }}
              >
                {translate("change_order_status_to_ok")}
                {orderStatusMutation.isPending ? <Spinner /> : null}
              </Button>
              {orderStatusMutation.isError && (
                <Alert>{translate(orderStatusMutation.error.message)}</Alert>
              )}
            </div>
          );
        }
        let value = JSON.stringify(order[key]);
        // Add some white space to make the text wrap.
        value = value.replace(/","/g, '", "');
        return (
          <div key={key}>
            <span className="strong">{key}</span>: {value}
          </div>
        );
      });
      const orderPageUrl =
        ordersQuery.data.homePageUrl + "/order/" + order.id + "/" + order.token;
      return (
        <div ref={ref} className="blue-box word-wrap" key={order.id}>
          {rows}
          <div>
            <a href={orderPageUrl} target="_blank" rel="noopener noreferrer">
              {translate("order_info_page")} &gt;&gt;
            </a>
          </div>
          <Link to={`/email-events/${order.customerEmail}`}>
            {translate("email_events_page")} &gt;&gt;
          </Link>
        </div>
      );
    });
  };

  const count = ordersQuery.isSuccess && (
    <div>
      {translate("total")}: {ordersQuery.data.orders.length}
    </div>
  );
  return (
    <div className="page-container max-width-container">
      <h2>
        <Translated translationKey="menu_webshop_orders" />
      </h2>
      <div className="blue-box">
        <TextInput
          listener={(value) => setSearchTerm(value)}
          value={searchTerm}
          placeholder="search"
          autoComplete="off"
        />
        <Button
          disabled={!searchTerm || searchTerm.length < 3}
          id="search-button"
          onClick={fetchOrders}
        >
          {translate("search_do")}
        </Button>
      </div>
      {(ordersQuery.isFetching || orderStatusMutation.isPending) && <Spinner />}
      {ordersQuery.isError && (
        <Alert>{translate(ordersQuery.error.message)}</Alert>
      )}
      {count}
      {ordersQuery.isSuccess && renderOrders()}
    </div>
  );
};

export default AdminWebshopOrdersPage;
