import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { IoSearch } from "react-icons/io5";
import Alert from "react-bootstrap/Alert";

import Translated from "./../Translated";
import Spinner from "./../../feature/Spinner";
import { IoCheckmarkSharp } from "react-icons/io5";
import { useTranslation } from "../TranslationProvider";
import { useAdminSearchUsers } from "../../../api-new";

const UserSearch = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const inputRef = useRef("");
  const [term, setTerm] = useState(inputRef.current);
  const searchQuery = useAdminSearchUsers(term);

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    setTerm(inputRef.current);
  };

  const renderUser = (user) => {
    return (
      <div key={user.id} className="blue-inner-box tight">
        <span
          className="touchable"
          onClick={() => navigate(`/user/${encodeURIComponent(user.id)}`)}
        >
          {user.id.toUpperCase()}, {user.name}, {user.schoolId.toUpperCase()}
        </span>
      </div>
    );
  };

  const renderAAUser = (user) => {
    return (
      <div key={user.ajokaistaUserId} className="blue-inner-box tight">
        <span
          className="touchable"
          onClick={() =>
            navigate(`/user/${encodeURIComponent(user.ajokaistaUserId)}`)
          }
        >
          {user.ajokaistaUserId.toUpperCase()}, {user.userName},{" "}
          {user.userEmail}
        </span>
      </div>
    );
  };

  const renderEaUser = (user) => {
    return (
      <div key={user.ajokaistaUserId} className="blue-inner-box tight">
        <span
          className="touchable"
          onClick={() =>
            navigate(`/user/${encodeURIComponent(user.ajokaistaUserId)}`)
          }
        >
          {user.ajokaistaUserId.toUpperCase()}, {user.userName},{" "}
          {user.userEmail}
        </span>
      </div>
    );
  };

  const renderGenericIntegrationUser = (user) => {
    return (
      <div key={user.userId} className="blue-inner-box tight">
        <span
          className="touchable"
          onClick={() => navigate(`/user/${encodeURIComponent(user.userId)}`)}
        >
          {user.integrationId.toUpperCase()}, {user.userId.toUpperCase()},{" "}
          {user.userName}, {user.userEmail}
        </span>
      </div>
    );
  };

  const renderVirtuaaliteoriaUser = (user) => {
    return (
      <div key={user.ajokaistaUserId} className="blue-inner-box tight">
        <span
          className="touchable"
          onClick={() =>
            navigate(`/user/${encodeURIComponent(user.ajokaistaUserId)}`)
          }
        >
          {user.ajokaistaUserId.toUpperCase()}, {user.userName},{" "}
          {user.userEmail}
        </span>
      </div>
    );
  };

  const renderEmailRequest = (request) => {
    return (
      <div key={request.id} className="blue-inner-box tight">
        <div
          className="touchable"
          onClick={() =>
            navigate(`/user/${encodeURIComponent(request.userId)}`)
          }
        >
          {request.email}, {request.userName}
        </div>
        <div>
          {request.schoolId.toUpperCase()}, {request.role},{" "}
          {request.requestDate}, {request.action}, [{request.consumeDate}]
        </div>
      </div>
    );
  };

  const renderEmailCredential = (credential) => {
    return (
      <div key={credential.id} className="blue-inner-box tight">
        <div
          className="touchable"
          onClick={() =>
            navigate(`/user/${encodeURIComponent(credential.userId)}`)
          }
        >
          {credential.userId}, {credential.email}
        </div>
        <div>
          {credential.hasPassword ? <IoCheckmarkSharp /> : "X"},{" "}
          {credential.createdDate}, {credential.isDeleted}
        </div>
      </div>
    );
  };

  const renderFacebookCredential = (credential) => {
    return (
      <div key={credential.id} className="blue-inner-box tight">
        <div
          className="touchable"
          onClick={() =>
            navigate(`/user/${encodeURIComponent(credential.userId)}`)
          }
        >
          {credential.userId}, {credential.name}
        </div>
      </div>
    );
  };

  const renderResultItem = (resultCatId, resultItem) => {
    switch (resultCatId) {
      case "users":
        return renderUser(resultItem);

      case "emailRequests":
        return renderEmailRequest(resultItem);

      case "emailCredentials":
        return renderEmailCredential(resultItem);

      case "facebookCredentials":
        return renderFacebookCredential(resultItem);

      case "aaIntegrationUsers":
        return renderAAUser(resultItem);

      case "virtuaaliteoriaUsers":
        return renderVirtuaaliteoriaUser(resultItem);

      case "eaUsers":
        return renderEaUser(resultItem);

      case "genericIntegrationUsers":
        return renderGenericIntegrationUser(resultItem);

      default:
        return <div>{JSON.stringify(resultItem)}</div>;
    }
  };

  const renderResults = () => {
    if (searchQuery.isFetching) {
      return <Spinner />;
    }

    if (searchQuery.isError) {
      return (
        <Alert>
          <Translated translationKey={searchQuery.error.message} />
        </Alert>
      );
    }

    if (!searchQuery.data) {
      return;
    }

    const resultCategoriesNames = Object.keys(searchQuery.data);
    const results = [];
    resultCategoriesNames.forEach((catId) => {
      const items = searchQuery.data[catId].map((item) =>
        renderResultItem(catId, item)
      );
      results.push(
        <div key={catId}>
          <h3 className="medium-top-margin">{catId}</h3>
          {items}
          <hr />
        </div>
      );
    });
    return results;
  };

  return (
    <div className="user-list">
      <div id="user-search-container">
        <Form onSubmit={(e) => onSubmit(e)} className="fade-in">
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>
                <IoSearch />
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="term"
                placeholder={translate("search_users")}
                onChange={(e) => (inputRef.current = e.target.value)}
              />
              <InputGroup>
                <Button id="user-search-button" onClick={(e) => onSubmit(e)}>
                  {translate("search_do")}
                </Button>
              </InputGroup>
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
      <div className="large-top-margin">{renderResults()}</div>
    </div>
  );
};

export default UserSearch;
