import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import InputGroup from "react-bootstrap/InputGroup";

import Spinner from "./../../feature/Spinner";
import { useTranslation } from "../TranslationProvider";
import { useAddUserComment, useGetUserComments } from "../../../api-new";

const UserComments = ({ userId }) => {
  const { translate } = useTranslation();
  const [comment, setComment] = useState("");
  const commentsQuery = useGetUserComments(userId);
  const commentMutation = useAddUserComment();
  const [customError, setCustomError] = useState(null);

  const addComment = (comment) => {
    commentMutation.mutate(
      {
        userId,
        comment,
      },
      {
        onSuccess: () => {
          setComment("");
          commentsQuery.refetch();
        },
      }
    );
  };

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!comment || comment.trim().length === 0) {
      setCustomError("invalid-input");
      return;
    }

    addComment(comment);
  };

  const renderComments = () => {
    if (commentsQuery.isError) {
      return <Alert>{translate(commentsQuery.error.message)}</Alert>;
    }

    const getUsernameFromComment = (comment) => {
      if (!comment.user.userName || comment.user.userName.length === 0) {
        return translate("not_available");
      }
      return comment.user.userName;
    };

    const comments = commentsQuery.data;
    if (!comments || comments.length === 0) {
      return null;
    }

    return comments.map((comment) => (
      <p key={comment.id}>
        <span className="semi-transparent">
          {getUsernameFromComment(comment)}, {comment.localDate}
        </span>
        <br />
        {comment.comment}
      </p>
    ));
  };

  return (
    <div className="comment-list">
      <div id="user-search-container">
        <Form onSubmit={onSubmit} className="fade-in">
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Control
                type="text"
                name="newComment"
                placeholder={translate("add_comment")}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onFocus={() => {
                  setCustomError(null);
                  commentMutation.reset();
                }}
              />
              <InputGroup>
                <Button
                  className="top-margin-small"
                  id="add-comment-button"
                  onClick={onSubmit}
                >
                  {translate("add_comment")}
                  {commentMutation.isPending && <Spinner />}
                </Button>
              </InputGroup>
            </InputGroup>
          </Form.Group>
        </Form>
        {(commentMutation.isError || customError) && (
          <Alert>
            {translate(commentMutation.error?.message || customError)}
          </Alert>
        )}
      </div>
      <br />
      {commentsQuery.isPending && <Spinner />}
      {renderComments()}
    </div>
  );
};

export default UserComments;
