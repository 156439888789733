import { useEffect, useRef, useState } from "react";
import Translated from "../../feature/Translated";
import Spinner from "./../../feature/Spinner";
import Alert from "react-bootstrap/Alert";
import FormGroup from "react-bootstrap/FormGroup";
import ControlLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import DateUtil from "../../../utils/date";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import SchoolComments from "../../feature/admin/SchoolComments";
import Logger from "../../../utils/logger";
import SingleItemAccordion from "../../feature/SingleItemAccordion";
import EventLogs from "../../feature/admin/EventLogs";
import { useNavigate, useParams } from "react-router-dom";
import { useUserData } from "../../../context/UserDataContext";
import { useTranslation } from "../../feature/TranslationProvider";
import { useSchoolData, useUpdateSchoolData } from "../../../api-new";

const AdminSchoolSettingsPage = () => {
  const { token, schoolId: tokenSchoolId } = useUserData();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [hasChanges, setHasChanges] = useState(false);
  const okToasterTimeoutRef = useRef(null);
  const schoolId = params.schoolId || tokenSchoolId;
  const schoolSettingsQuery = useSchoolData(schoolId);
  const [school, setSchool] = useState(schoolSettingsQuery.data?.school);
  const schoolSettingsMutation = useUpdateSchoolData();

  useEffect(() => {
    if (!token) {
      return;
    }

    return () => clearTimeout(okToasterTimeoutRef.current);
  }, []);

  useEffect(() => {
    if (schoolSettingsQuery.isSuccess) {
      setHasChanges(false);
      setSchool(schoolSettingsQuery.data.school);
    }

    if (schoolSettingsQuery.isError) {
      Logger.error(err);
    }
  }, [schoolSettingsQuery.data]);

  const postSettings = () => {
    if (!token) {
      return;
    }

    schoolSettingsMutation.mutate(
      { schoolId, schoolData: school },
      {
        onSuccess: () => {
          okToasterTimeoutRef.current = setTimeout(
            () => schoolSettingsMutation.reset(),
            5000
          );
        },
      }
    );
  };

  const handleSchoolInputChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    updateSchoolState(e.target.name, value);
  };
  const updateSchoolState = (field, value) => {
    setSchool((prev) => ({ ...prev, [field]: value }));
    setHasChanges(true);
  };

  const makeFormGroup = (label, fieldName, classes) => {
    classes = classNames("settings-field", classes);
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          className={classes}
          name={fieldName}
          placeholder={label}
          value={school[fieldName] ? school[fieldName] : ""}
          onChange={handleSchoolInputChange}
        />
      </FormGroup>
    );
  };

  const makeCheckbox = (label, fieldName, classes) => {
    classes = classNames("settings-field", classes);
    return (
      <Form.Check
        type="checkbox"
        className={classes}
        name={fieldName}
        checked={school[fieldName]}
        onChange={handleSchoolInputChange}
        label={label}
      ></Form.Check>
    );
  };

  const buildAmortizationSelection = () => {
    const daysLabel = " " + translate("days");
    const selections = [2, 7, 15, 30, 45];
    const currentValue = school.userAmortizationPeriodDays;

    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id="amortizationSelection"
          bsstyle="warning"
          title={currentValue + daysLabel}
        >
          {selections.map((selectableValue) => {
            return (
              <Dropdown.Item
                id={selectableValue}
                key={selectableValue}
                onClick={() => {
                  updateSchoolState(
                    "userAmortizationPeriodDays",
                    selectableValue
                  );
                }}
              >
                <span>
                  {selectableValue === currentValue ? (
                    <span className="settings-selected-dropdown-value ">
                      {selectableValue + daysLabel}
                    </span>
                  ) : (
                    selectableValue + daysLabel
                  )}
                </span>
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      </ButtonToolbar>
    );
  };

  const editSchoolLimits = (schoolId) => {
    navigate("/school-limits/" + schoolId);
  };

  const orderOneTimeKeys = (schoolId) => {
    navigate("/school-orders/" + schoolId);
  };

  const listInvoices = (schoolId) => {
    navigate("/school-invoices/" + schoolId);
  };

  const editTeachers = (schoolId) => {
    navigate("/school-teachers/" + schoolId);
  };

  const renderEventLogs = (schoolId) => {
    return (
      <SingleItemAccordion
        title={<Translated translationKey="event_logs" />}
        content={<EventLogs targetId={schoolId} targetType={"school"} />}
      />
    );
  };

  const renderSchoolActions = () => {
    return (
      <div>
        <div key={school.id}>
          <Button bsstyle="link" onClick={() => editSchoolLimits(school.id)}>
            <Translated translationKey="limits" />
          </Button>
          <Button bsstyle="link" onClick={() => orderOneTimeKeys(school.id)}>
            <Translated translationKey="orders" />
          </Button>
          <Button bsstyle="link" onClick={() => listInvoices(school.id)}>
            <Translated translationKey="invoices" />
          </Button>
          <Button bsstyle="link" onClick={() => editTeachers(school.id)}>
            <Translated translationKey="teachers" />
          </Button>
        </div>
        {renderEventLogs(school.id)}
        <hr />
      </div>
    );
  };

  const renderForm = () => {
    const errorMessage = schoolSettingsMutation.isError ? (
      <Alert bsstyle="warning">
        <Translated translationKey={schoolSettingsMutation.error.message} />
      </Alert>
    ) : null;

    const saveOkMessage = schoolSettingsMutation.isSuccess ? (
      <Alert bsstyle="success">OK</Alert>
    ) : null;

    const saveDisabled = schoolSettingsMutation.isPending;

    return (
      <div>
        <h3>{school.id.toUpperCase()}</h3>
        {renderSchoolActions()}

        <div className="white-box">
          <form>
            <fieldset disabled={false}>
              {makeFormGroup(translate("vat_id"), "vatID")}
              {makeFormGroup(translate("name"), "name")}
              {makeFormGroup(translate("street_address"), "streetAddress")}
              {makeFormGroup(
                translate("zip_code"),
                "postalNumber",
                "settings-limited-field"
              )}
              {makeFormGroup(
                translate("postal_location"),
                "postalLocation",
                "settings-limited-field"
              )}
              {makeFormGroup(
                translate("phone_number"),
                "phoneNumber",
                "settings-limited-field"
              )}
              {makeFormGroup(translate("email"), "email")}
              {makeFormGroup(translate("url"), "url")}
              {makeFormGroup(
                translate("student_codes_batch_amount"),
                "studentCodesBatchAmount"
              )}
              {makeFormGroup(translate("password"), "password")}
              {makeFormGroup(
                translate("license_free_until", {
                  date: DateUtil.dateTime(school.prepaidDate),
                }),
                "prepaidDate"
              )}
              {makeFormGroup(
                translate("soft_closing_date", {
                  date: DateUtil.dateTime(school.softClosingDate),
                }),
                "softClosingDate"
              )}
              {makeFormGroup(
                translate("closing_date", {
                  date: DateUtil.dateTime(school.closingDate),
                }),
                "closingDate"
              )}
              {makeFormGroup(
                translate("invoicing_interval_months", {
                  date: school.invoicingIntervalMonths,
                }),
                "invoicingIntervalMonths"
              )}
              {makeFormGroup(
                translate("user_discount_percentage"),
                "userDiscountPercentage"
              )}
              {makeFormGroup(translate("student_amount"), "estimatedStudents")}
              {makeFormGroup(translate("school_series"), "series")}
              {makeFormGroup(translate("parent_school_id"), "parentSchoolId")}
              {makeFormGroup(
                translate("preferred_language"),
                "preferredLanguageId"
              )}

              {makeCheckbox(
                translate("allow_closed_reminders"),
                "allowClosedReminders"
              )}
              {makeCheckbox(
                translate("allow_receipt_send"),
                "allowReceiptSend"
              )}
              {makeCheckbox(translate("is_closed"), "isClosed")}
              {makeCheckbox(translate("has_teacher_page"), "hasTeacherPage")}

              <div>
                <Translated translationKey="user_amortization_title" />
                {buildAmortizationSelection()}
              </div>

              <h4>
                <Translated translationKey="invoice_details" />
              </h4>
              {makeFormGroup(translate("invoice_receiver"), "invoiceName")}
              {makeFormGroup(
                translate("street_address"),
                "invoiceStreetAddress"
              )}
              {makeFormGroup(
                translate("zip_code"),
                "invoicePostalNumber",
                "settings-limited-field"
              )}
              {makeFormGroup(
                translate("postal_location"),
                "invoicePostalLocation",
                "settings-limited-field"
              )}
              {makeFormGroup(translate("cost_center"), "invoiceCostCenter")}

              <h4>
                <Translated translationKey="e_invoice_details" />
              </h4>
              {makeFormGroup(translate("e_invoice_address"), "eInvoiceAddress")}
              {makeFormGroup(
                translate("e_invoice_operator_code"),
                "eInvoiceOperatorCode"
              )}

              <div className="centered">
                {errorMessage ? errorMessage : saveOkMessage}
                <Button
                  id="school-settings-submit-button"
                  disabled={saveDisabled}
                  onClick={() => (!saveDisabled ? postSettings() : null)}
                  variant={hasChanges ? "danger" : "primary"}
                  className="top-margin-small"
                >
                  {schoolSettingsMutation.isPending ? (
                    <Spinner className="primary" />
                  ) : (
                    <Translated translationKey="save" />
                  )}
                </Button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  };

  let content;
  if (schoolSettingsQuery.isPending) {
    content = <Spinner className="primary" />;
  } else if (schoolSettingsQuery.isError) {
    content = <Alert>{translate(schoolSettingsQuery.error.message)}</Alert>;
  } else if (!school) {
    content = <Spinner className="primary" />;
  } else {
    content = (
      <div>
        {renderForm()}
        <SchoolComments schoolId={schoolId} />
        <br />
      </div>
    );
  }

  return (
    <div
      id="school-settings-page"
      className="page-container max-width-container"
    >
      {content}
    </div>
  );
};

export default AdminSchoolSettingsPage;
